import ResizeHandle from '@components/common/ResizeHandle'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material'
import { Document } from '@services/api'
import React, { useState } from 'react'
import { Panel, PanelGroup } from 'react-resizable-panels'
import { TransitionProps } from '@mui/material/transitions'
import moment from 'moment'
import MedicalOrderForm from '@components/medical-order/MedicalOrderForm'
import DocumentListViewer from '@components/dialogs/DocumentListViewer'
import Quotation from './Quotation/Quotation'
import { OrderDto } from '@services/dtos'
import { Add, Save } from '@mui/icons-material'
import { ExamSelectorDialog } from '@components/dialogs/ExamSelectorDialog'
import { useAppDispatch } from '@hooks/reduxHooks'
import {
  doAddMedicalOrder,
  doRemoveMedicalOrder,
} from '@state/reducers/orderReducer'

type Props = {
  order?: OrderDto
  isOpen: boolean
  onClose: () => void
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const MedicalOrderDialog: React.FC<Props> = ({ order, isOpen, onClose }) => {
  const dispatch = useAppDispatch()
  // --------------------------------------- State ---------------------------------------
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null,
  )
  const [isExamSelectorVisible, setIsExamSelectorVisible] = useState(false)

  const handleExamSelectionChange = (examIds: number[]) => {
    if (!order) {
      return
    }

    for (const exId of examIds) {
      dispatch(doAddMedicalOrder({ examId: exId, orderId: order.id }))
    }
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        fullScreen
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', background: '#3f3f3f' }}>
          <Toolbar style={{ color: '#FAFAFB' }}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <Save />
            </IconButton>
            <Button autoFocus color="inherit" onClick={onClose}>
              Sauvegarder
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ backgroundColor: '#F3F4F6' }}>
          {!order ? (
            <></>
          ) : (
            <div className="h-full">
              <PanelGroup direction="horizontal">
                <Panel collapsible order={1} defaultSize={20}>
                  <div className="flex flex-row items-center mb-4">
                    <Avatar
                      className="border border-1 border-gray-400 mr-2"
                      style={{ width: '60px', height: '60px' }}
                      src="https://education.gsu.edu/files/2019/08/Headshot_Placeholder.jpg"
                    />
                    <div className="leading-tight ">
                      <h4 className="text-md font-bold">
                        {order.patient.firstName} {order.patient.lastName}
                      </h4>
                      <Typography variant="body2" gutterBottom>
                        {moment(order.patient.birthDate).format('DD/MM/YYYY')} (
                        {moment().diff(order.patient.birthDate, 'years')} ans)
                      </Typography>
                      <p className="text-xs leading-tight">
                        Exam: 0 Solde: 0.00 €
                      </p>
                    </div>
                  </div>
                  <Accordion>
                    <AccordionSummary>
                      <Typography>Compte Rendu</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* Render the report here */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary>
                      <Typography>Fichiers attachés</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* {medicalOrder.documents.length > 0 && */}
                      {/*   medicalOrder.documents.map((doc) => ( */}
                      {/*     <Button */}
                      {/*       key={doc.id} */}
                      {/*       onClick={() => { */}
                      {/*         setSelectedDocument(doc) */}
                      {/*       }} */}
                      {/*     > */}
                      {/*       {renderDocumentIcon(doc)} */}
                      {/*     </Button> */}
                      {/*   ))} */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary>
                      <Typography>Historique</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* Render the report here */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary>
                      <Typography>Reglements</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* Render the report here */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary>
                      <Typography>Bordereaux</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* Render the report here */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary>
                      <Typography>Relances</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* Render the report here */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary>
                      <Typography>Rejets</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* Render the report here */}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary>
                      <Typography>sms</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* Render the report here */}
                    </AccordionDetails>
                  </Accordion>
                </Panel>
                <ResizeHandle horizontal className="" />
                <Panel order={2} defaultSize={80}>
                  <PanelGroup direction="vertical">
                    <Paper className="p-2 h-full">
                      <h4 className="text-md font-bold">Demande</h4>
                      <Divider
                        sx={{
                          marginTop: 2,
                          marginBottom: 1,
                        }}
                      />
                      <MedicalOrderForm order={order} />
                      <Button
                        variant="contained"
                        onClick={() => setIsExamSelectorVisible(true)}
                        size="small"
                        style={{ marginTop: '12px' }}
                      >
                        <Add />
                        Ajouter un exam
                      </Button>
                      {order.medicalOrders.length !== 0 && (
                        <Quotation order={order} />
                      )}
                    </Paper>
                    {/* <Paper className="p-2 h-full">
                      <h4 className="text-md font-bold">Examens</h4>
                      <Divider sx={{ marginBottom: 3, marginTop: 1 }} />
                      <DialogFactnPreco medicalOrder={medicalOrder} />
                    </Paper>
                    <Paper className="p-2">
                      <h4 className="text-md font-bold">
                        Cotation-CCAM / NGAP
                      </h4>
                      <Divider sx={{ marginBottom: 1, marginTop: '0.5px' }} />
                      <DialogFactnPreco medicalOrder={medicalOrder} />
                    </Paper> */}
                    {/* Quotation mockup */}
                  </PanelGroup>
                </Panel>
              </PanelGroup>
            </div>
          )}
        </DialogContent>
      </Dialog>
      {order && (
        <DocumentListViewer
          isOpen={selectedDocument !== null}
          // documents={order.documents}
          documents={[]}
          onClose={() => {
            setSelectedDocument(null)
          }}
          selectedDocument={selectedDocument || undefined}
          onSelectedDocumentChange={(doc) => {
            setSelectedDocument(doc)
          }}
        />
      )}
      {order && (
        <ExamSelectorDialog
          isOpen={isExamSelectorVisible}
          onClose={() => setIsExamSelectorVisible(false)}
          handleConfirm={handleExamSelectionChange}
          excludedExams={order.medicalOrders.map((mo) => mo.exam)}
        />
      )}
    </>
  )
}

export default MedicalOrderDialog
