import React, { useState } from 'react'
import {
  Button,
  Popper,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Box,
  Tabs,
  Tab,
} from '@mui/material'
import { useIntl } from 'react-intl'
import TabWorkList from './TabWorkList'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar'
import { WorklistTabsEnum } from '../../../common/interfaces'

interface TabPanelProps {
  children?: React.ReactNode
  value: WorklistTabsEnum
  tabValue: WorklistTabsEnum
}

function TabPanel(props: TabPanelProps) {
  const { children, value, tabValue } = props

  return (
    <Box sx={{ height: '100%' }}>
      {value === tabValue && (
        <Box sx={{ paddingLeft: 1, paddingRight: 2, height: '87vh' }}>
          {children}
        </Box>
      )}
    </Box>
  )
}

const myTabsProps: {
  labelTab: WorklistTabsEnum
  icon: string
  actions: string[]
}[] = [
  {
    labelTab: WorklistTabsEnum.pendingRegistration,
    icon: 'fas fa-user-plus',
    actions: [
      'registerPatient',
      'registerPatientWithCV',
      'updateMO',
      'patientDetails',
      'patientMerge',
      'ViewMo',
    ],
  },
  {
    labelTab: WorklistTabsEnum.registered,
    icon: 'fas fa-user-check',
    actions: ['viewMO', 'updateMO', 'patientDetails'],
  },
  {
    labelTab: WorklistTabsEnum.toQuote,
    icon: 'fas fa-user-tag',
    actions: ['viewMO'],
  },
  {
    labelTab: WorklistTabsEnum.medicalOrderCancelled,
    icon: 'fas fa-user-minus',
    actions: ['viewMO'],
  },
  {
    labelTab: WorklistTabsEnum.toInvoice,
    icon: 'fas fa-file-invoice-dollar',
    actions: ['patientDetails', 'patientBill'],
  },
]

export default function Worklists() {
  const [value, setValue] = useState(WorklistTabsEnum.pendingRegistration)
  const intl = useIntl()

  const handleChange = (
    _: React.SyntheticEvent,
    newValue: WorklistTabsEnum,
  ) => {
    setValue(newValue)
  }

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const togglePopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  // useEffect(() => {
  //   dispatch(findMedicalOrders({ includeTransport: true }))
  // }, [filters.page])

  return (
    <Box>
      <Box
        sx={{
          marginTop: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="exams-tabs"
          sx={{
            marginLeft: 1,
            marginBottom: '-16px',
            paddingBottom: '2px',
            display: 'flex',
          }}
        >
          {myTabsProps.map((el, index) => (
            <Tab
              label={intl.formatMessage({ id: el.labelTab })}
              {...a11yProps(index, el.labelTab as WorklistTabsEnum)}
              value={el.labelTab}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '5px 5px 0px 0px',
                marginRight: '8px',
                marginLeft: '0px',
              }}
              key={index}
            />
          ))}
        </Tabs>

        <Button
          variant="contained"
          color="secondary"
          size="small"
          sx={{
            borderRadius: '5px',
            marginTop: '5px',
            marginRight: '10px',
            textTransform: 'none',
            whiteSpace: 'nowrap',
            color: '#fff',
          }}
          onClick={togglePopper}
          aria-describedby={id} // Ajout de l'ID du Popper pour une meilleure accessibilité
        >
          Selection filtre
        </Button>
      </Box>

      {myTabsProps.map((el, index) => (
        <TabPanel value={value} tabValue={el.labelTab} key={index}>
          <TabWorkList dataTabs={el} actions={el.actions} />
        </TabPanel>
      ))}

      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
        <Box
          sx={{
            border: 1,
            borderColor: '#E0E0E0',
            p: 2,
            borderRadius: '10px',
            bgcolor: 'background.paper',
          }}
        >
          <Grid item xs={12} md={12}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Période</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Aujourd'hui"
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Demain"
                />
                <FormControlLabel value="-1" control={<Radio />} label="Hier" />
                <FormControlLabel value="4" control={<Radio />} label="+4j" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateRangeCalendar']}>
                <DateRangeCalendar />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Box>
      </Popper>
    </Box>
  )
}

function a11yProps(index: number, value: WorklistTabsEnum) {
  return {
    id: value.toString(),
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
