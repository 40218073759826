import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import {
  IconButton,
  Input,
  InputAdornment,
  Tooltip,
  debounce,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useIntl } from 'react-intl'
import SearchIcon from '@material-ui/icons/Search'
import { useMemo } from 'react'
import { findMedicalOrders } from '@state/thunks/medicalOrderThunk'
import { setMedicalOrderFilters } from '@state/reducers/medicalOrderReducer'
import { Site } from '@services/api'
import { doFindOrders } from '@state/reducers/orderReducer'

export function MedicalOrderToolbar() {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const filters = useAppSelector(({ medicalOrder }) => medicalOrder.filters)
  const user = useAppSelector(({ auth }) => auth.user)

  const handleRefresh = () => {
    // TODO move this back to finDMedicalOrders
    // dispatch(
    //   findMedicalOrders({
    //     includeTransport: true,
    //     // isTodayOnly: true,
    //   }),
    // )

    dispatch(doFindOrders())
  }

  const onSearchChange = (value: string) => {
    searchMedicalOrders(value)
  }

  const searchMedicalOrders = useMemo(() => {
    return debounce((value: string) => {
      dispatch(
        setMedicalOrderFilters({
          ...filters,
          search: value,
          page: 0,
        }),
      )
    }, 300)
  }, [filters])

  const onSiteChange = (ev: SelectChangeEvent<string>) => {
    const siteCode = ev.target.value as string
    dispatch(
      setMedicalOrderFilters({
        ...filters,
        siteCode: siteCode,
      }),
    )
  }

  const sites = useMemo(() => {
    const _sites = (user as any & { sites: Site[] })?.sites
    if (_sites.length === 1) {
      dispatch(
        setMedicalOrderFilters({
          ...filters,
          siteCode: _sites[0].code,
        }),
      )
    }
    return _sites
  }, [user])

  return (
    <GridToolbarContainer>
      <div>
        <GridToolbarColumnsButton
          sx={{
            backgroundColor: '#3f3f3f',
            color: '#fff',
            marginRight: '10px',
            '&:hover': {
              backgroundColor: '#3f3f3f70',
            },
          }}
        />
        <GridToolbarDensitySelector
          sx={{
            backgroundColor: '#3f3f3f',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#3f3f3f70',
            },
          }}
        />
        <Tooltip title={intl.formatMessage({ id: 'refresh' })}>
          <IconButton onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        {/* <GridToolbarExport /> */}
      </div>

      <Select
        value={filters.siteCode}
        onChange={onSiteChange}
        style={{ minWidth: '200px', marginRight: '16px' }}
        size="small"
      >
        {sites.map((site: Site) => (
          <MenuItem key={site.id} value={site.code}>
            {site.label}
          </MenuItem>
        ))}
      </Select>
      <Input
        onChange={(ev) => {
          onSearchChange(ev.target.value)
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        placeholder={intl.formatMessage({
          id: 'searchPatient',
          defaultMessage: 'Search Patient',
        })}
        style={{
          height: '40px',
          width: '250px',
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '0 10px',
        }}
        disableUnderline
      />
    </GridToolbarContainer>
  )
}
