import { useAppDispatch } from '@hooks/reduxHooks'
import { Divider, Link, Tab, Tabs } from '@mui/material'
import { ResipService } from '@services/ResipService'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { getCartVitalUrl } from '@state/thunks/cardReadThunk'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

const urlsMap: {
  [key: string]: (resipId: string, cardReaderId: string) => Promise<string>
} = {
  getDashboardUrl: ResipService.getDashboardUrl,
  getScorUrl: ResipService.getScorUrl,
  getTeletransmissionUrl: ResipService.getTeletransmissionUrl,
  getReturnManagementUrl: ResipService.getReturnManagementUrl,
  getUserListUrl: ResipService.getUserListUrl,
  getUserConfigurationUrl: ResipService.getUserConfigurationUrl,
  getGeneralParametersUrl: ResipService.getGeneralParametersUrl,
  getConfigurationDisplayUrl: ResipService.getConfigurationDisplayUrl,
  getVersionsUrl: ResipService.getVersionUrl,
}

const ResipLayout = () => {
  const dispatch = useAppDispatch()
  const [cardReaderId, setcardReaderId] = useState('')
  const [currentUrl, setCurrentUrl] = useState('')
  const [activeTab, setactiveTab] = useState('')

  const intl = useIntl()

  useEffect(() => {
    dispatch(getCartVitalUrl())
      .unwrap()
      .then((dto) => {
        setcardReaderId(dto.id)
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: err.message,
            options: { variant: 'error' },
          }),
        )
      })
  }, [])

  const handleLinkClick = (key: string) => {
    const fn = urlsMap[key]
    if (fn) {
      fn('1', cardReaderId).then(setCurrentUrl)
    }
    setactiveTab(key)
  }

  const links = useMemo(() => {
    const keys = Object.keys(urlsMap)
    return keys.map((key) => (
      <Tab
        key={key}
        label={intl.formatMessage({ id: `resip.${key}` })}
        value={key}
        onClick={() => handleLinkClick(key)}
        component={Link}
      />
    ))
  }, [urlsMap, activeTab])

  return (
    <div style={{ minHeight: '100vh', backgroundColor: 'white' }}>
      <Tabs style={{ backgroundColor: 'white' }} value={activeTab}>
        {links}
      </Tabs>
      <Divider />
      {!!currentUrl && (
        <iframe
          src={currentUrl}
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )}
    </div>
  )
}

export default ResipLayout
