import { CouvertureResponseDto, VitalCardDto } from 'src/common/interfaces'
import { axios, getConfigs, IRequestConfig } from './api'
import { CreatePrecotationDto, PrecotationDto } from './dtos'

export class BillinglService {
  // private static endpoint = process.env.REACT_APP_RESIP_URL || ''
  private static apiBackendEndpoint = process.env.REACT_APP_API_URL || ''

  static getCouverture(
    dto: VitalCardDto,
    medicalOrderId: number,
    dateLecture: string,
  ): Promise<CouvertureResponseDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.apiBackendEndpoint}/billing/coverage`,
        {},
      )
      configs.data = { medicalOrderId, dateLecture, vitalCardData: dto }
      axios(configs, resolve, reject)
    })
  }

  static createPrecot(dto: PrecotationDto): Promise<PrecotationDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.apiBackendEndpoint}/billing/precotation/${dto.id_medical_order}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static getPrecotations(medicalOrderId: number): Promise<PrecotationDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'get',
        'application/json',
        `${this.apiBackendEndpoint}/billing/precotation?medicalOrderIds=${medicalOrderId}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static updatePrectotation(
    id: number,
    dto: PrecotationDto,
  ): Promise<PrecotationDto> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'put',
        'application/json',
        `${this.apiBackendEndpoint}/billing/precotation/${id}`,
        {},
      )
      configs.data = dto
      axios(configs, resolve, reject)
    })
  }

  static removePrecotation(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'delete',
        'application/json',
        `${this.apiBackendEndpoint}/billing/precotation/${id}`,
        {},
      )
      axios(configs, resolve, reject)
    })
  }

  static savePrecotations(
    medicalOrderId: number,
    data: PrecotationDto[],
  ): Promise<PrecotationDto[]> {
    return new Promise((resolve, reject) => {
      const configs: IRequestConfig = getConfigs(
        'post',
        'application/json',
        `${this.apiBackendEndpoint}/billing/precotation/${medicalOrderId}`,
        {},
      )
      configs.data = data
      axios(configs, resolve, reject)
    })
  }
}
