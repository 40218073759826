import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks'
import {
  removeUnfinishedPrecot,
  updatePrecotation,
} from '../../../../state/reducers/billingReducer'
import {
  CCAMDto,
  CreatePrecotationDto,
  NGAPDto,
  PrecotationDto,
} from '../../../../services/dtos'
import { DataGridPro } from '@mui/x-data-grid-pro'
import CcamAndNgapSearchComponent from '@components/billing/CcamAndNgapSearchComponent'
import DeleteIcon from '@mui/icons-material/Delete'
import { codeEntentePrealable } from '@utils/constants'

interface QuotationTableProps {
  rows: PrecotationDto[]
  onAddRow: () => void
  medicalOrderId: number
}

const QuotationTable: React.FC<QuotationTableProps> = ({ rows }) => {
  const dispatch = useAppDispatch()

  const handleRemove = (cotation: PrecotationDto) => {
    dispatch(removeUnfinishedPrecot(cotation))
  }

  const getModifierColumn = (key: string, name: string) => {
    return {
      field: key,
      headerName: name,
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: (params) => {
        const { row } = params as any as { row: PrecotationDto }
        const modifiers = row.modificateur?.split('')
        return modifiers || []
      },
      valueSetter: (params) => {
        const { value, row } = params
        return { ...row, [key]: value || 'DefaultValue' }
      },
    }
  }

  const handleCellEditCommit = async (params) => {
    const { id, field, value } = params
    const updatedRow = {
      ...rows.find((row) => {
        if (row.id !== undefined) {
          return row.id === id
        } else {
          return row.identifier === id
        }
      }),
      [field]: value,
    }

    try {
      dispatch(updatePrecotation(updatedRow as PrecotationDto))
    } catch (error) {
      console.error('Failed to update the row:', error)
      // Optionally, show an error message to the user
    }
  }

  return (
    <>
      <DataGridPro
        onCellEditCommit={handleCellEditCommit}
        sx={{ height: 400, width: '100%', mt: 2 }}
        columns={[
          {
            field: 'cotation',
            headerName: 'Code Acte',
            flex: 1,
            renderCell: (params) => (
              <div className="flex flex-row items-center">
                {params.row.cotation}
                <CcamAndNgapSearchComponent
                  onChange={(v) => {
                    const row = params.api.getRow(params.id) as PrecotationDto
                    let newRow = { ...row }
                    if (v.cotationType === 'CCAM') {
                      const val = v.value as CCAMDto
                      newRow = {
                        ...newRow,
                        cotation: val.code,
                        prix_unitaire: val.prixNonOptam,
                        modificateur: val.modificateurs || '',
                        cotation_type: 'CCAM',
                      }
                    } else {
                      const val = v.value as NGAPDto
                      newRow = {
                        ...newRow,
                        cotation: val.code,
                        prix_unitaire: val.prix,
                        cotation_type: 'NGAP',
                        modificateur: '',
                        m1: '',
                        m2: '',
                        m3: '',
                        m4: '',
                      }
                    }
                    dispatch(updatePrecotation(newRow))
                  }}
                />
              </div>
            ),
          },
          {
            field: 'cotation_type',
            headerName: 'Cotation',
            flex: 1,
            editable: true,
          },
          {
            ...getModifierColumn('m1', 'M1'),
          },
          {
            ...getModifierColumn('m2', 'M2'),
          },
          {
            ...getModifierColumn('m3', 'M3'),
          },
          {
            ...getModifierColumn('m4', 'M4'),
          },
          {
            field: 'association',
            headerName: 'Association',
            flex: 1,
            editable: true,
          },
          {
            field: 'prix_unitaire',
            headerName: 'Prix Unitaire',
            flex: 1,
            editable: true,
          },
          {
            field: 'total',
            headerName: 'Total',
            flex: 1,
            editable: true,
          },
          {
            field: 'code_entente_prealable',
            headerName: 'Entente Préalable',
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
              <select
                value={params.value as any}
                onChange={(e) =>
                  params.api.setEditCellValue(
                    {
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    },
                    e,
                  )
                }
              >
                {codeEntentePrealable.map((code) => (
                  <option key={code.value} value={code.value}>
                    {code.label}
                  </option>
                ))}
              </select>
            ),
            renderCell: (params) => {
              const code = codeEntentePrealable.find(
                (c) => c.value.toString() === params.value,
              )
              console.log(params.value, code)
              return code ? code.label : ''
            },
          },
          {
            field: 'date_entente_prealable',
            headerName: 'Date Entente Préalable',
            flex: 1,
            editable: true,
            renderEditCell: (params) => (
              <input
                type="date"
                value={params.value as any}
                onChange={(e) => {
                  console.log(e.target.value)
                  params.api.setEditCellValue(
                    {
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    },
                    e,
                  )
                }}
              />
            ),
          },
          {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
              <div className="flex justify-center">
                <IconButton onClick={() => handleRemove(params.row)}>
                  <DeleteIcon sx={{ color: 'red' }} />
                </IconButton>
              </div>
            ),
          },
        ]}
        isCellEditable={(params) => {
          const hasCotationValue =
            params.row.cotation != null && params.row.cotation !== ''

          const editableFields = ['m1', 'm2', 'm3', 'm4']

          if (editableFields.includes(params.field)) {
            return hasCotationValue
          }

          return params.colDef.editable as any
        }}
        getRowId={(row) => row.id || row.identifier}
        rows={rows}
      />
    </>
  )
}

export default QuotationTable
