import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from '@reduxjs/toolkit'
import { PrecotationDto } from '@services/dtos'
import { BillinglService } from '../../services/BillingService'

export interface BillingState {
  precotation: PrecotationDto[]
}

const initialState: BillingState = {
  precotation: [],
}

export const doCreatePrecotation = createAsyncThunk(
  'billing/createPrecotation',
  async (data: PrecotationDto) => {
    const response = await BillinglService.createPrecot(data)
    return response
  },
)

export const doSavePrecotations = createAsyncThunk<
  PrecotationDto[],
  { medicalOrderId: number; data: PrecotationDto[] }
>('billing/savePrecotations', async ({ medicalOrderId, data }) => {
  const response = await BillinglService.savePrecotations(medicalOrderId, data)
  return response
})

export const doFindPrecotations = createAsyncThunk(
  'billing/findPrecotations',
  async (medicalOrderId: number) => {
    const response = await BillinglService.getPrecotations(medicalOrderId)
    return response
  },
)

export const doUpdatePrecotation = createAsyncThunk(
  'billing/updatePrecotation',
  async (data: { id: number; dto: PrecotationDto }) => {
    const response = await BillinglService.updatePrectotation(data.id, data.dto)
    return response
  },
)

export const doRemovePrecotation = createAsyncThunk(
  'billing/removePrecotation',
  async (id: number) => {
    const response = await BillinglService.removePrecotation(id)
    return response
  },
)

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setPrecotation: (state, action: PayloadAction<PrecotationDto[]>) => {
      state.precotation = action.payload
    },
    removeUnfinishedPrecot: (
      state,
      { payload }: PayloadAction<PrecotationDto>,
    ) => {
      if (payload.id) {
        state.precotation = state.precotation.filter((p) => p.id !== payload.id)
      } else if (payload.identifier) {
        state.precotation = state.precotation.filter(
          (p) => p.identifier !== payload.identifier,
        )
      }
    },
    updatePrecotation: (state, { payload }: PayloadAction<PrecotationDto>) => {
      console.log('payload', payload)
      if (payload.id) {
        const index = state.precotation.findIndex((p) => p.id === payload.id)
        if (index !== -1) {
          state.precotation[index] = payload
        }
      } else if (payload.identifier) {
        const index = state.precotation.findIndex(
          (p) => p.identifier === payload.identifier,
        )
        if (index !== -1) {
          state.precotation[index] = payload
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doCreatePrecotation.fulfilled, (state, action) => {
      const precotationIndex = state.precotation.findIndex(
        (prec) => prec.cotation === action.payload.cotation,
      )
      if (precotationIndex === -1) {
        state.precotation.push(action.payload)
      } else {
        state.precotation[precotationIndex] = action.payload
      }
    })
    // builder.addCase(doFindPrecotations.fulfilled, (state, action) => {
    //   state.precotation = action.payload
    // })
    builder.addCase(doUpdatePrecotation.fulfilled, (state, action) => {
      const index = state.precotation.findIndex(
        (p) => p.id === action.payload.id,
      )
      if (index !== -1) {
        state.precotation[index] = action.payload
      }
    })
    builder.addCase(doRemovePrecotation.fulfilled, (state, action) => {
      state.precotation = state.precotation.filter(
        (p) => p.id !== action.meta.arg,
      )
    })
  },
})

export const { setPrecotation, removeUnfinishedPrecot, updatePrecotation } =
  billingSlice.actions
export default billingSlice.reducer
