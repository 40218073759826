import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FindOrdersQuery, OrderDto, UpdateOrderDto } from '@services/dtos'
import { PaginatedDto } from '@services/extendedType'
import { OrderService } from '@services/OrderService'

export interface OrderState {
  orders: OrderDto[]
  filters: {
    search?: string
    page: number
    limit: number
    total: number
  }
  selectedOrder: OrderDto | null
}

const initialState: OrderState = {
  orders: [],
  filters: {
    limit: 50,
    page: 0,
    total: 0,
  },
  selectedOrder: null,
}

export const doFindOrders = createAsyncThunk<PaginatedDto<OrderDto>, void>(
  'order/findOrders',
  async (_, { getState }) => {
    const { filters } = (getState() as { order: OrderState }).order
    const query: FindOrdersQuery = {
      limit: filters.limit,
      page: filters.page,
      search: filters.search,
    }
    return OrderService.get(query)
  },
)

export const doAddMedicalOrder = createAsyncThunk<
  OrderDto,
  { orderId: number; examId: number }
>('order/addMedicalOrder', async ({ orderId, examId }) => {
  return OrderService.addMedicalOrder(orderId, examId)
})

export const doRemoveMedicalOrder = createAsyncThunk<
  OrderDto,
  { orderId: number; medicalOrderId: number }
>('order/removeMedicalOrder', async ({ orderId, medicalOrderId }) => {
  return OrderService.removeMedicalOrder(orderId, medicalOrderId)
})

export const doUpdateOrder = createAsyncThunk<
  OrderDto,
  { id: number; dto: UpdateOrderDto }
>('order/updateOrder', async ({ id, dto }) => {
  return OrderService.update(id, dto)
})
const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderFilters: (
      state,
      action: PayloadAction<{
        limit?: number
        page?: number
        total?: number
        search?: string
      }>,
    ) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    setSelectedOrder: (state, action: PayloadAction<OrderDto | null>) => {
      state.selectedOrder = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doFindOrders.fulfilled, (state, action) => {
      state.orders = action.payload.datas
      state.filters.total = action.payload.totalCount
    })
    builder.addCase(doAddMedicalOrder.fulfilled, (state, action) => {
      state.selectedOrder = action.payload
    })
    builder.addCase(doRemoveMedicalOrder.fulfilled, (state, action) => {
      state.selectedOrder = action.payload
    })
    builder.addCase(doUpdateOrder.fulfilled, (state, { payload }) => {
      state.selectedOrder = payload
      const index = state.orders.findIndex((order) => order.id === payload.id)
      if (index !== -1) {
        state.orders[index] = payload
      }
    })
  },
})

export default orderSlice.reducer

export const { setOrderFilters, setSelectedOrder } = orderSlice.actions
