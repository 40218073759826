import { MedicalOrder, UpdateMedicalOrderDto } from '@services/api'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MedicalOrderSchema } from '../../utils/schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  MenuItem,
  Chip,
  Button,
  LinearProgress,
  CircularProgress,
} from '@mui/material'
import { DatePicker, TimePicker } from '@mui/x-date-pickers-pro'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { findAll } from '@state/thunks/siteThunk'
import { RppsAutocompleteByNameInput } from '@components/RppsAutocompleteByNameInput'
import moment from 'moment'
import { updateMedicalOrder } from '@state/thunks/medicalOrderThunk'
import { doFindMembers } from '@state/thunks/memberThunk'
import { ControlledAutocompleteInput } from '@components/inputs'
import { enqueueSnackbar } from '@state/reducers/alertReducer'
import { OrderDto } from '@services/dtos'
import { doUpdateOrder } from '@state/reducers/orderReducer'

type Props = {
  order: OrderDto
}
export const MedicalOrderForm: React.FC<Props> = ({ order }) => {
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateMedicalOrderDto>({
    resolver: yupResolver(MedicalOrderSchema),
    defaultValues: {
      siteId: order.site.id,
      attendingDoctor: order.attendingDoctor,
      referringDoctor: order.referringDoctor,
      consultingDoctor: order.consultingDoctor,
      secretaryId: order.secretary?.id,
      registerDate: order.registeredAt
        ? (moment(order.registeredAt) as any)
        : undefined,
      registerTime: order.registeredAt
        ? (moment(order.registeredAt) as any)
        : undefined,
      comments: order.comments,
      isEmergency: order.isEmergency,
      isHoliday: order.isHoliday,
      isNightShift: order.isNightShift,
    },
  })
  const dispatch = useAppDispatch()

  // ------------------------- state ------------------------------
  const { sites, members } = useAppSelector(({ site, member }) => ({
    sites: site.sites,
    members: member.members,
  }))

  const attendingDoctor = watch('attendingDoctor')
  const referringDoctor = watch('referringDoctor')
  const consultingDoctor = watch('consultingDoctor')
  const registerDate = watch('registerDate')
  const registerTime = watch('registerTime')
  const isEmergency = watch('isEmergency')
  const isNightShift = watch('isNightShift')
  const isHoliday = watch('isHoliday')

  const [isEditing, setisEditing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // ------------------------- useEffects -------------------------
  useEffect(() => {
    dispatch(findAll())
  }, [])

  useEffect(() => {
    dispatch(doFindMembers({ limit: 400 }))
  }, [])

  // ------------------------- Functions ----------------
  const onSubmit = (data: UpdateMedicalOrderDto) => {
    setIsSubmitting(true)
    dispatch(
      doUpdateOrder({
        id: order.id,
        dto: { ...data, registerDate, registerTime },
      }),
    ).then(() => {
      setIsSubmitting(false)
      setisEditing(false)
      dispatch(
        enqueueSnackbar({
          message: 'Mise à jour effectuée',
          options: {
            variant: 'success',
          },
        }),
      )
    })
  }

  console.log(errors)
  return (
    <div>
      <div className="flex flex-row justify-end align-center gap-2 translate-y-[-46px] translate-x-[-8px]">
        {isEditing ? (
          <>
            <Button
              size="small"
              style={{ color: 'white', fontWeight: 'bold', padding: '2px 4px' }}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              className="flex flex-row items-center gap-1"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <CircularProgress size="12px" />
              ) : (
                <i className="fas fa-save"></i>
              )}
              <span>Enregistrer</span>
            </Button>
            <Button
              size="small"
              style={{
                fontWeight: 'bold',
                padding: '2px 4px',
              }}
              variant="outlined"
              onClick={() => {
                setisEditing(false)
              }}
              disabled={isSubmitting}
              className="flex flex-row items-center gap-1"
            >
              <i className="fas fa-times"></i>
              <span className="">Annuler</span>
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              setisEditing(true)
            }}
            size="small"
            style={{
              color: 'white',
              fontWeight: 'bold',
              padding: '2px 8px',
            }}
            variant="contained"
          >
            <i className="fas fa-edit mr-2"></i>
            Modifier
          </Button>
        )}
      </div>
      {isEditing ? (
        <>
          {isSubmitting && (
            <LinearProgress className="w-full translate-y-[-40px]" />
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextField
                  disabled={!isEditing}
                  size="small"
                  fullWidth
                  label="Site"
                  select
                  {...register('siteId')}
                  defaultValue={order.siteId}
                >
                  {sites.datas.map((site) => (
                    <MenuItem key={site.id} value={site.id}>
                      {site.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  fullWidth
                  defaultValue={new Array<string>()}
                  SelectProps={{
                    multiple: true,
                  }}
                  label="UF"
                  select
                ></TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  fullWidth
                  defaultValue={new Array<string>()}
                  SelectProps={{
                    multiple: true,
                  }}
                  label="UF Localisation"
                  select
                ></TextField>
              </Grid>
              <Grid item xs={3}>
                <ControlledAutocompleteInput
                  control={control}
                  options={members.datas.map((member) => ({
                    label: `${member.firstName} ${member.lastName}`,
                    value: member.id,
                  }))}
                  input="secretaryId"
                  defaultValue={
                    order.secretary
                      ? {
                        value: order.secretary.id,
                        label: `${order.secretary?.firstName} ${order.secretary?.firstName}`,
                      }
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <RppsAutocompleteByNameInput
                  onChange={(v) => setValue('attendingDoctor', v)}
                  value={attendingDoctor || null}
                  label="Médecin Responsable"
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <RppsAutocompleteByNameInput
                  onChange={(v) => setValue('referringDoctor', v)}
                  value={referringDoctor || null}
                  label="Médecin Prescripteur"
                  size="small"
                />
              </Grid>
              <Grid item xs={3}>
                <RppsAutocompleteByNameInput
                  onChange={(v) => setValue('consultingDoctor', v)}
                  value={consultingDoctor || null}
                  label="Médecin Consultant"
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={1}></Grid>

            <Grid container spacing={1} mt={1}>
              <Grid item xs={4} className="flex flex-row items-center">
                <DatePicker
                  value={moment(registerDate, 'DD-MM-YYYY') as any}
                  onChange={(v) => {
                    setValue('registerDate', v?.format('DD-MM-YYYY'))
                  }}
                  label="Accueil"
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                    },
                  }}
                />
                <span className="mx-1">a</span>
                <TimePicker
                  value={moment(registerTime, 'HH:mm') as any}
                  onChange={(v) => setValue('registerTime', v?.format('HH:mm'))}
                  slotProps={{
                    textField: {
                      size: 'small',
                      style: { width: '180px' },
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                className="flex flex-row items-center self-center flex-wrap h-13"
              >
                <FormControlLabel
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: '0.75rem',
                      },
                    },
                  }}
                  control={
                    <Switch
                      onChange={(ev) =>
                        setValue('isEmergency', ev.target.checked)
                      }
                      defaultChecked={isEmergency}
                      size="small"
                    />
                  }
                  label="Urgent"
                />
                <FormControlLabel
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: '0.75rem',
                      },
                    },
                  }}
                  control={
                    <Switch
                      onChange={(ev) =>
                        setValue('isEmergency', ev.target.checked)
                      }
                      size="small"
                    />
                  }
                  label="Astreinte"
                />
                <FormControlLabel
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: '0.75rem',
                      },
                    },
                  }}
                  control={
                    <Switch
                      onChange={(ev) =>
                        setValue('isNightShift', ev.target.checked)
                      }
                      defaultChecked={isNightShift}
                      size="small"
                    />
                  }
                  defaultChecked={isNightShift}
                  label="Nuit"
                />
                <FormControlLabel
                  slotProps={{
                    typography: {
                      sx: {
                        fontSize: '0.75rem',
                      },
                    },
                  }}
                  control={
                    <Switch
                      onChange={(ev) =>
                        setValue('isHoliday', ev.target.checked)
                      }
                      defaultChecked={isHoliday}
                      size="small"
                    />
                  }
                  label="Dimanche / J.férié"
                />
              </Grid>
              <Grid xs={5} item>
                <TextField
                  {...register('comments')}
                  multiline
                  fullWidth
                  rows={3}
                  label="Commentaires"
                />
              </Grid>
            </Grid>
          </form>
        </>
      ) : (
        <Grid container>
          <Grid item xs={3}>
            <h4 className="text-sm font-bold leader-tight">Site</h4>
            <p className="text-gray-600 text-xs leader-tight">
              {order.site.label}
            </p>
          </Grid>
          <Grid item xs={3}>
            <h4 className="text-sm font-bold leader-tight">
              Docteur Responsable
            </h4>
            <p className="text-gray-600 text-xs leader-tight">
              {attendingDoctor
                ? `${attendingDoctor.firstName} ${attendingDoctor.lastName}`
                : '-'}
            </p>
          </Grid>
          <Grid item xs={3}>
            <h4 className="text-sm font-bold leader-tight">
              Docteur Prescripteur
            </h4>
            <p className="text-gray-600 text-xs leader-tight">
              {referringDoctor
                ? `${referringDoctor.firstName} ${referringDoctor.lastName}`
                : '-'}
            </p>
          </Grid>
          <Grid item xs={3}>
            <h4 className="text-sm font-bold leader-tight">
              Docteur Consultant
            </h4>
            <p className="text-gray-600 text-xs leader-tight">
              {consultingDoctor
                ? `${consultingDoctor.firstName} ${consultingDoctor.lastName}`
                : '-'}
            </p>
          </Grid>
          <Grid container className="mt-4">
            <Grid item xs={3}>
              <h4 className="text-sm font-bold leader-tight">Secrétaire</h4>
              <p className="text-gray-600 text-xs leader-tight">
                {order.secretary
                  ? `${order.secretary.firstName} ${order.secretary.lastName}`
                  : '-'}
              </p>
            </Grid>
            <Grid item xs={3}>
              <h4 className="text-sm font-bold leader-tight">
                Acceuil Date et Heure
              </h4>
              <p className="text-gray-600 text-xs leader-tight">
                {moment(order.registeredAt).format('DD/MM/YYYY [a] HH:mm')}
              </p>
            </Grid>
            <Grid item xs={6}>
              {order.isEmergency && (
                <Chip
                  label={
                    <>
                      <i className="fas fa-ambulance"></i> Urgent
                    </>
                  }
                  style={{
                    backgroundColor: '#c44569',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  size="small"
                />
              )}
              {order.isNightShift && (
                <Chip
                  label="Nuit"
                  style={{
                    backgroundColor: '#30336b',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  size="small"
                />
              )}
              {order.isHoliday && (
                <Chip
                  label="Dimanche / J. férié"
                  style={{
                    backgroundColor: '#f0932b',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  size="small"
                  className="ml-1"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default MedicalOrderForm
