import { createAsyncThunk } from '@reduxjs/toolkit'
import { Actions } from '@state/actions'
import { FindPatientsQuery, PatientDto, PatientService } from '@services/api'
import { BillinglService } from '@services/BillingService'
import { CouvertureDto, VitalCardDto } from 'src/common/interfaces'
import { PaginatedDto } from '@services/extendedType'
import { PatientsState } from '@state/reducers/patientsReducer'
import {
  PatientMergeHistoryDto,
  PatientServiceV2,
} from '@services/PatientService'

export const fetchPatients = createAsyncThunk<
  PaginatedDto<PatientDto>,
  void,
  any
>(Actions.PATIENTS_FIND_ALL, async (_, { rejectWithValue, getState }) => {
  try {
    const { patientsFilter } = (getState() as { patients: PatientsState })
      .patients

    console.log({ patientsFilter })

    return await PatientService.findAllv2(patientsFilter)
  } catch (e: any) {
    if (e.response) {
      return rejectWithValue(e.response.data.message)
    }
    return rejectWithValue(e.response.data.message)
  }
})

export const fetchOnePatientById = createAsyncThunk(
  Actions.PATIENT_FIND_ONE,
  async (id: number, { rejectWithValue }) => {
    try {
      return await PatientService.findOne({ id })
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const updatePatient = createAsyncThunk<PatientDto, PatientDto, any>(
  Actions.PATIENT_UPDATE,
  async (data: PatientDto, { rejectWithValue }) => {
    try {
      return await PatientService.update(data)
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const getCouverture = createAsyncThunk<
  CouvertureDto,
  { dto: VitalCardDto; medicalOrderId: number; lectureDate: string },
  any
>(
  Actions.GET_PATIENT_COVERAGE,
  async ({ dto, lectureDate, medicalOrderId }, { rejectWithValue }) => {
    try {
      const response = await BillinglService.getCouverture(
        dto,
        medicalOrderId,
        lectureDate,
      )
      if (response.erreur) {
        return rejectWithValue(response.erreurMessage)
      }
      return response.couverture
    } catch (e: any) {
      if (e.response) {
        return rejectWithValue(e.response.data.message)
      }
      return rejectWithValue(e.response.data.message)
    }
  },
)

export const doMergePatients = createAsyncThunk<
  void,
  { sourcePatientId: number; targetPatientId: number },
  any
>(Actions.MergePatients, async ({ sourcePatientId, targetPatientId }) => {
  await PatientServiceV2.mergePatient(sourcePatientId, targetPatientId)
})

export const doCancelMerge = createAsyncThunk<
  void,
  { pId: number; mergeId: number },
  any
>(Actions.MergePatients, async ({ pId, mergeId }) => {
  await PatientServiceV2.cancelPatientMerge(pId, mergeId)
})

export const doFetchMergeHistory = createAsyncThunk<
  PatientMergeHistoryDto[],
  number
>(Actions.FETCH_MERGE_HISTORY, async (patientId) => {
  return await PatientServiceV2.findMergeHistory(patientId)
})
