import { Box, Button, Dialog, DialogContent } from '@mui/material'
import { Document } from '@services/api'
import React from 'react'

export const renderDocumentIcon = (document: Document) => {
  const fileName = document.url.split('/').pop()
  const extension = fileName?.split('.').pop()?.toLowerCase()
  let icon: React.ReactNode = null

  switch (extension) {
    case 'pdf':
      icon = <i className="far fa-file-pdf"></i>
      break
    case 'doc':
    case 'docx':
      icon = <i className="far fa-file-word"></i>
      break
    case 'xls':
    case 'xlsx':
      icon = <i className="far fa-file-excel"></i>
      break
    case 'ppt':
    case 'pptx':
      icon = <i className="far fa-file-powerpoint"></i>
      break
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'bmp':
    case 'svg':
      icon = <i className="far fa-file-image"></i>
      break
    default:
      icon = <i className="far fa-file"></i>
  }
  return (
    <>
      {icon} <span className="ml-1">{fileName}</span>
    </>
  )
}

type Props = {
  documents: Document[]
  isOpen: boolean
  onClose: () => void
  selectedDocument?: Document
  onSelectedDocumentChange: (document: Document) => void
}

const DocumentListViewer: React.FC<Props> = ({
  documents,
  isOpen,
  onClose,
  selectedDocument,
  onSelectedDocumentChange,
}) => {
  const getFileUrl = (document: Document) => {
    return `${process.env.REACT_APP_API_URL}${
      document.url
    }?token=${localStorage.getItem('authToken')}`
  }

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
      <DialogContent className="h-[calc(100vh-200px)]">
        <Box className="overflow-x-scroll whitespace-nowrap mb-2">
          {documents.map((document) => (
            <Button
              key={document.id}
              onClick={() => {
                onSelectedDocumentChange(document)
              }}
              variant="outlined"
              className="inline-block"
              size="small"
              style={{
                marginRight: '4px',
                borderColor:
                  document === selectedDocument
                    ? 'black'
                    : 'rgba(162, 155, 254,0.6)',
                backgroundColor:
                  document === selectedDocument
                    ? '#a29bfe'
                    : 'rgba(162, 155, 254,0.6)',
                color: document === selectedDocument ? 'white' : 'white',
              }}
            >
              {renderDocumentIcon(document)}
            </Button>
          ))}
        </Box>
        {selectedDocument && (
          <iframe
            src={getFileUrl(selectedDocument)}
            width="100%"
            height="100%"
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default DocumentListViewer
