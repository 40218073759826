import React, { useCallback, useEffect, useState } from 'react'
import {
  Grid,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Divider,
  Chip,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  debounce,
  FormLabel,
  Tooltip,
} from '@mui/material'
import { DatePicker } from '@mui/lab'
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined'
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined'
import theme from '@utils/theme'
import { FormattedMessage } from 'react-intl'
import { RppsAutocompleteByNameInput } from '@components/RppsAutocompleteByNameInput'
import { MedicalOrder, RppsDto } from '@services/api'
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ReactAutocomplete from 'react-autocomplete'
import { BanService } from '@services/BanServices'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InputLabel from '@mui/material/InputLabel'
import { useIntl } from 'react-intl'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { formatSsn, formatSsnPrefix } from '@utils/ssnUtil'
import NirhistoryModal from '../../billing/NirhistoryModal'
import { parcourSoinsOptions, pathwayhealth } from '@utils/constants'
import { patientCoverageSchema } from '@utils/schemas'
import { useAppSelector } from '@hooks/reduxHooks'

type PatientInit = {
  id: string
  sex: 'M' | 'F' | 'U'
  nom: string
  prenom: string
  dateNaissance: Date
  qualite: string
  rang: number
  nirPatient: string

  assureNom: string
  assurePrenom: string
  nirAssure: string

  insNomNaissance: string
  premierPrenom: string
  listePrenoms: [string]
  lieuNaissance: string
  ins: string
  oid: string

  natureAssurance: string
  exoneration: string
  amo: string
  debutAmo: Date | null
  finAmo: Date | null
  amc: string
  debutAmc: Date | null
  finAmc: Date | null
  numeroAdherent: string
  gestion: string
  cmuC2s: boolean
  ame: boolean
  atNumber: string
  atDate: Date | null
  maternityDate: Date | null

  parcoursSoins: string
  medecinTraitantDeclare: RppsDto
  medecinTraitant: RppsDto
  birthPlaceExtra: string
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  const getProgressText = (value: number) => {
    if (value === 0) {
      return 'En attente d\'admition'
    }
    if (value === 25) {
      return 'En salle d\'attente'
    }
    if (value === 50) {
      return 'En consultation'
    }
    if (value === 75) {
      return 'En attente de paiement'
    }
    if (value === 100) {
      return 'Terminé'
    }
    if (value === -1) {
      return 'Annulé'
    }
    return ''
  }

  return (
    <Box>
      <Box sx={{ width: '100%', textAlign: 'center', marginBottom: '5px' }}>
        <Typography variant="body2" color="text.secondary">
          {getProgressText(props.value)}
        </Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          variant="determinate"
          color="secondary"
          value={props.value}
        />
      </Box>
    </Box>
  )
}

type Props = {
  medicalOrder?: MedicalOrder
}

export const PatientForm: React.FC<Props> = ({ medicalOrder }) => {
  // handleSubmit
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<PatientInit>({
    resolver: yupResolver(patientCoverageSchema),
    defaultValues: {
      assureNom: '',
      assurePrenom: '',
      nom: '',
      prenom: '',
      debutAmo: null,
      finAmo: null,
      debutAmc: null,
      finAmc: null,
    },
  })
  const BanServices = new BanService()
  const debutAmo = watch('debutAmo')
  const finAmo = watch('finAmo')
  const debutAmc = watch('debutAmc')
  const finAmc = watch('finAmc')
  const medecinTraitantDeclare = watch('medecinTraitantDeclare')
  const medecinTraitant = watch('medecinTraitant')
  const maternityDate = watch('maternityDate')
  const ame = watch('ame')
  const cmuC2s = watch('cmuC2s')
  const atDate = watch('atDate')

  const [modifyPatient, setModifyPatient] = useState(false)
  const [modifyInsured, setModifyInsured] = useState(false)
  const [modifyIns, setModifyIns] = useState(false)
  const [modifySocialSecurity, setModifySocialSecurity] = useState(false)
  const [modifyCarePathway, setModifyCarePathway] = useState(false)
  const [progress, setProgress] = React.useState(0)
  const [isManualBirthPlaceVisible, setIsManualBirthPlaceVisible] =
    useState(false)
  const [birthPlaceSearch, setBirthPlaceSearch] = useState('')
  const [birthPlace, setBirthPlace] = useState('')
  const [birthPlaceOptions, setBirthPlaceOptions] = useState([
    { label: '', value: '' },
  ])
  const [InsuredDropDown, setInsuredDropDown] = useState(true)
  const [InsDropDown, setInsDropDown] = useState(true)
  const [SocialSecurityDropDown, setSocialSecurityDropDown] = useState(true)
  const [CarePathwayDropDown, setCarePathwayDropDown] = useState(true)
  const [webServiceInfoDropDown, setWebServiceInfoDropDown] = useState(true)

  const intl = useIntl()

  const patientCoverage = useAppSelector(
    ({ patients }) => patients.patientCouverture,
  )

  const onBirthPlaceChange = (value: string) => {
    const addr = value.replace(/\s/g, '-')
    setBirthPlaceSearch(addr)
    setBirthPlace(addr)
  }

  const setIsManualBirthPlaceVisibleAndRemouve = (checked: boolean) => {
    setIsManualBirthPlaceVisible(checked)
    if (!checked) {
      setValue('birthPlaceExtra', '')
    }
  }

  const debouncedSearchBirth = useCallback(
    debounce((searchTerm: any) => {
      BanServices.search(searchTerm)
        .then((data) => {
          if (data.length > 0) {
            const storeData = data.map((d) => ({
              label: d.place,
              value: d.id,
            }))
            setBirthPlaceOptions(storeData)
          }
        })
        .catch((error) => {
          console.error('Failed to fetch addresses:', error)
        })
    }, 500),
    [],
  )

  useEffect(() => {
    debouncedSearchBirth(birthPlaceSearch)
  }, [birthPlaceSearch, debouncedSearchBirth])

  useEffect(() => {
    if (!patientCoverage) {
      return
    }
    setValue('nom', patientCoverage.assureNom)
    setValue('prenom', patientCoverage.assurePrenom)
    if (patientCoverage.beneficiaireDateNaissance) {
      setValue(
        'dateNaissance',
        new Date(patientCoverage.beneficiaireDateNaissance),
      )
    }
    setValue('nirPatient', patientCoverage.assureNir)
    setValue('qualite', patientCoverage.qualite.toString())
    setValue('rang', patientCoverage.rangNaissance)
    setValue('assureNom', patientCoverage.beneficiaireNom)
    setValue('assurePrenom', patientCoverage.beneficiairePrenom)
    setValue('nirAssure', patientCoverage.beneficiaireNir)
    if (patientCoverage.dateDebutAmo) {
      setValue('debutAmo', new Date(patientCoverage.dateDebutAmo))
    }
    if (patientCoverage.dateFinAmo) {
      setValue('finAmo', new Date(patientCoverage.dateFinAmo))
    }
  }, [patientCoverage])

  const onSubmit = (data) => console.log(data)
  const [isModalHistoryOpen, setIsModalHistoryOpen] = useState(false)

  const handleCloseModal = () => {
    setIsModalHistoryOpen(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* patient */}
      <Grid container spacing={2} sx={{ height: '100%', paddingRight: '24px' }}>
        <Grid
          xs={12}
          sx={{ marginTop: '11px', paddingTop: '5px', paddingBottom: '20px' }}
        >
          <Divider />
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs={12 / 3}>
            <ListItem
              sx={{ border: 1, borderColor: '#C6CAD5', borderRadius: '100px' }}
            >
              <ListItemIcon>
                <Icon
                  className="fas fa-user"
                  style={{ color: theme.palette.primary.main, width: '100%' }}
                />
              </ListItemIcon>
              <ListItemText
                primary={'Patient'}
                secondary={'Johan ZOLI'}
                secondaryTypographyProps={{
                  style: { color: 'black', textTransform: 'capitalize' },
                }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={12 / 3}>
            <ListItem
              sx={{ border: 1, borderColor: '#C6CAD5', borderRadius: '100px' }}
            >
              <ListItemIcon>
                <Icon
                  className="fas fa-folder"
                  style={{ color: theme.palette.primary.main, width: '100%' }}
                />
              </ListItemIcon>
              <ListItemText
                primary={'N° dossier'}
                secondary={'12 144 333'}
                secondaryTypographyProps={{
                  style: { color: 'black', textTransform: 'capitalize' },
                }}
              />
            </ListItem>
          </Grid>
          <Grid item xs={12 / 3}>
            <ListItem
              sx={{ border: 1, borderColor: '#C6CAD5', borderRadius: '100px' }}
            >
              <ListItemIcon>
                <Icon
                  className="fas fa-calendar"
                  style={{ color: theme.palette.primary.main, width: '100%' }}
                />
              </ListItemIcon>
              <ListItemText
                primary={'Status'}
                secondary={'Actif'}
                secondaryTypographyProps={{
                  style: { color: 'black', textTransform: 'capitalize' },
                }}
              />
            </ListItem>
          </Grid>
        </Grid>
        <Grid xs={12} spacing={2}>
          <Divider sx={{ marginTop: '11px', paddingTop: '5px' }}>
            <Chip
              label="Patient"
              onDelete={() => setModifyPatient(!modifyPatient)}
              deleteIcon={
                modifyPatient ? <ModeOutlinedIcon /> : <EditOffOutlinedIcon />
              }
              variant="outlined"
            />
          </Divider>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={12}>
            <RadioGroup
              row
              aria-labelledby="sexe"
              defaultValue={'M'}
              {...register('sex')}
            >
              <FormControlLabel
                style={{ color: '#48bef9', fontWeight: 'bold' }}
                value="M"
                disabled={!modifyPatient}
                control={
                  <Radio
                    sx={{
                      color: '#48bef9',
                      '&.Mui-checked': {
                        color: '#48bef9',
                      },
                    }}
                  />
                }
                label="Homme"
              />
              <FormControlLabel
                value="F"
                style={{ color: '#f48dde', fontWeight: 'bold' }}
                disabled={!modifyPatient}
                control={
                  <Radio
                    sx={{
                      color: '#f48dde',
                      '&.Mui-checked': {
                        color: '#f48dde',
                      },
                    }}
                  />
                }
                label="Femme"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              label="Nom"
              disabled={!modifyPatient}
              {...register('nom')}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              label="Prénom"
              {...register('prenom')}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={!modifyPatient}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              label="Date de naissance"
              value={watch('dateNaissance')}
              onChange={(newValue) =>
                newValue === null ? null : setValue('dateNaissance', newValue)
              }
              disabled={!modifyPatient}
              renderInput={(props) => (
                <TextField size="small" fullWidth {...props} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              label="NIR Patient"
              {...register('nirPatient')}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={!modifyPatient}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              label="Qualité"
              {...register('qualite')}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={!modifyPatient}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              label="Rang"
              {...register('rang')}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={!modifyPatient}
            />
          </Grid>
        </Grid>
        {/* assuré */}
        <Grid xs={12} spacing={2}>
          <Divider sx={{ marginTop: '35px' }}>
            <Chip
              label="Assuré"
              onClick={() => setInsuredDropDown(!InsuredDropDown)}
              onDelete={() => setModifyInsured(!modifyInsured)}
              deleteIcon={
                modifyInsured ? <ModeOutlinedIcon /> : <EditOffOutlinedIcon />
              }
              variant="outlined"
            />
          </Divider>
        </Grid>
        {InsuredDropDown && (
          <Grid container item spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="Assuré Nom"
                {...register('assureNom')}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled={!modifyInsured}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="Assuré Prénom"
                {...register('assurePrenom')}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled={!modifyInsured}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="NIR Assuré"
                {...register('nirAssure')}
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled={!modifyInsured}
              />
            </Grid>
          </Grid>
        )}

        {/* INS */}
        <Grid xs={12} spacing={2}>
          <Divider sx={{ marginTop: '35px' }}>
            <Chip
              label="INS"
              onClick={() => setInsDropDown(!InsDropDown)}
              onDelete={() => setModifyIns(!modifyIns)}
              deleteIcon={
                modifyIns ? <ModeOutlinedIcon /> : <EditOffOutlinedIcon />
              }
              variant="outlined"
            />
          </Divider>
        </Grid>
        {InsDropDown ? (
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="Nom de naissance"
                {...register('insNomNaissance')}
                fullWidth
                disabled={!modifyIns}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="Premier prénom"
                {...register('premierPrenom')}
                fullWidth
                disabled={!modifyIns}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                label="Liste des prénoms"
                {...register('listePrenoms')}
                fullWidth
                disabled={!modifyIns}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    paddingRight: '30px',
                  }}
                >
                  {!isManualBirthPlaceVisible && (
                    <>
                      <ReactAutocomplete
                        menuStyle={{
                          borderRadius: '3px',
                          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                          background: '#fff',
                          padding: '10px',
                          fontSize: '1em',
                          position: 'fixed',
                          overflow: 'auto',
                          maxHeight: '50%',
                          zIndex: 100,
                        }}
                        inputProps={{
                          style: {
                            width: '100%',
                            fontSize: '1em',
                            border: '1px solid #ADB1BA',
                            backgroundColor: 'transparent',
                            focus: 'black',
                            borderRadius: '5px',
                            padding: '8.5px 14px',
                          },
                          hover: {
                            border: '1px solid #000000de',
                          },
                          disabled: isManualBirthPlaceVisible || !modifyIns,
                          placeholder: 'Lieu de naissance',
                        }}
                        wrapperStyle={{ width: '100%' }}
                        getItemValue={(item) => item.label}
                        items={birthPlaceOptions}
                        renderItem={(item: any, isHighlighted: any) => (
                          <div
                            style={{
                              background: isHighlighted ? 'lightblue' : 'white',
                              cursor: 'pointer',
                              padding: '5px',
                            }}
                          >
                            {item.label}
                          </div>
                        )}
                        value={birthPlaceSearch}
                        onChange={(e) =>
                          setBirthPlaceSearch(
                            e.target.value.replace(/\s/g, '-'),
                          )
                        }
                        onSelect={onBirthPlaceChange}
                      />
                    </>
                  )}
                </Grid>
                {isManualBirthPlaceVisible && (
                  <Grid container spacing={1} justifyItems="space-between">
                    <Grid item xs={8}>
                      <TextField
                        size="small"
                        variant="outlined"
                        label={
                          <FormattedMessage
                            id="birthPlace"
                            defaultMessage="Lieu de naissance"
                          />
                        }
                        fullWidth
                        {...register('lieuNaissance')}
                        InputLabelProps={{ shrink: true }}
                        placeholder='Ex: "Paris"'
                        inputProps={{ maxLength: 35 }}
                      />
                    </Grid>
                    {/* {errors?.birthPlace && <FormHelperText error>{errors?.birthPlace?.message}</FormHelperText>} */}
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        variant="outlined"
                        placeholder='Ex: "13000"'
                        type="number"
                        fullWidth
                        {...register('birthPlaceExtra')}
                        inputProps={{ maxLength: 5 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    {/* {errors?.birthPlaceDepartment && <FormHelperText error>{errors?.birthPlaceDepartment?.message}</FormHelperText>} */}
                  </Grid>
                )}
                <Grid item justifyContent="space-between" container>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!modifyIns}
                        checked={isManualBirthPlaceVisible}
                        onChange={(e) =>
                          setIsManualBirthPlaceVisibleAndRemouve(
                            e.target.checked,
                          )
                        }
                        color="primary"
                      />
                    }
                    label={
                      isManualBirthPlaceVisible
                        ? 'Retour à la recherche'
                        : 'Introuvable / Né(e) à l\'étranger ?'
                    }
                  />
                  {isManualBirthPlaceVisible && (
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '0.75rem',
                        color: 'grey',
                        textAlign: 'center',
                      }}
                    >
                      <FormattedMessage
                        id="birthPlaceTip"
                        defaultMessage="99 pour l'étranger"
                      />
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="INS"
                {...register('ins')}
                fullWidth
                disabled={!modifyIns}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="OID"
                {...register('oid')}
                fullWidth
                disabled={!modifyIns}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body1" color="initial">
                Historique des Ins du patient
              </Typography>
              <Tooltip placement="top" title="Historique des ins">
                <Button
                  variant="text"
                  sx={{ borderRadius: '100px', marginLeft: '5px' }}
                  onClick={() => setIsModalHistoryOpen(true)}
                >
                  <Icon className="fa fa-history" />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        ) : null}
        {/* Parcours de soins */}
        <Grid xs={12} spacing={2}>
          <Divider sx={{ marginTop: '35px' }}>
            <Chip
              label="Parcours de soins"
              onDelete={() => setModifyCarePathway(!modifyCarePathway)}
              onClick={() => setCarePathwayDropDown(!CarePathwayDropDown)}
              deleteIcon={
                modifyCarePathway ? (
                  <ModeOutlinedIcon />
                ) : (
                  <EditOffOutlinedIcon />
                )
              }
              variant="outlined"
            />
          </Divider>
        </Grid>
        {CarePathwayDropDown && (
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={12} sx={{ marginBottom: '10px' }}>
              <LinearProgressWithLabel value={progress} />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Parcours de soins
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="select-parcour"
                  label="Parcours de soins"
                  {...register('parcoursSoins')}
                  disabled={!modifyCarePathway}
                  size="small"
                >
                  {parcourSoinsOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.value} | {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="outlined"
                fullWidth
                disabled={!modifyCarePathway}
                onClick={() => setProgress(progress >= 100 ? 0 : progress + 25)}
              >
                Next status
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="outlined"
                fullWidth
                disabled={!modifyCarePathway}
                onClick={() => setProgress(-1)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <RppsAutocompleteByNameInput
                disabled={!modifyCarePathway}
                label={
                  <FormattedMessage
                    id="familyDoctor"
                    defaultMessage="Médecin traitant (prescripteur)"
                  />
                }
                onChange={(value) => console.log(value)}
                size="small"
                value={medecinTraitant as unknown as RppsDto}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <RppsAutocompleteByNameInput
                disabled={!modifyCarePathway}
                label={
                  <FormattedMessage
                    id="precriptorDoctor"
                    defaultMessage="Médecin traitant (famille)"
                  />
                }
                onChange={(value) => console.log(value)}
                size="small"
                value={medecinTraitantDeclare as unknown as RppsDto}
              />
            </Grid>
          </Grid>
        )}
        {/* couverture */}
        <Grid xs={12} spacing={2}>
          <Divider sx={{ marginTop: '35px' }}>
            <Chip
              label="Couverture sociale"
              onDelete={() => setModifySocialSecurity(!modifySocialSecurity)}
              onClick={() => setSocialSecurityDropDown(!SocialSecurityDropDown)}
              deleteIcon={
                modifySocialSecurity ? (
                  <ModeOutlinedIcon />
                ) : (
                  <EditOffOutlinedIcon />
                )
              }
              variant="outlined"
            />
          </Divider>
        </Grid>
        {SocialSecurityDropDown && (
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="Nature de l'Assurance"
                {...register('natureAssurance')}
                fullWidth
                disabled={!modifySocialSecurity}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="Exonération"
                {...register('exoneration')}
                fullWidth
                disabled={!modifySocialSecurity}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="Numéro AT"
                {...register('atNumber')}
                fullWidth
                disabled={!modifySocialSecurity}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="AT Date"
                value={atDate}
                onChange={(newValue) =>
                  newValue === null ? null : setValue('atDate', newValue)
                }
                disabled={!modifySocialSecurity}
                renderInput={(props) => (
                  <TextField size="small" fullWidth {...props} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="Date de maternité"
                value={maternityDate}
                onChange={(newValue) =>
                  newValue === null ? null : setValue('maternityDate', newValue)
                }
                disabled={!modifySocialSecurity}
                renderInput={(props) => (
                  <TextField size="small" fullWidth {...props} />
                )}
              />
            </Grid>
            <Grid item xs={5} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!modifySocialSecurity}
                    checked={cmuC2s}
                    onChange={(e) => setValue('cmuC2s', e.target.checked)}
                    color="primary"
                  />
                }
                label="CMU/C2S"
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!modifySocialSecurity}
                    checked={ame}
                    onChange={(e) => setValue('ame', e.target.checked)}
                    color="primary"
                  />
                }
                label="AME"
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={10} md={10}>
              <Divider>
                <Typography variant="body2">AMO</Typography>
              </Divider>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                label="Code de la caisse"
                {...register('amo')}
                fullWidth
                disabled={!modifySocialSecurity}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="Début AMO"
                value={debutAmo}
                onChange={(newValue) =>
                  newValue === null ? null : setValue('debutAmo', newValue)
                }
                disabled={!modifySocialSecurity}
                renderInput={(props) => (
                  <TextField size="small" fullWidth {...props} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="Fin AMO"
                value={finAmo}
                onChange={(newValue) =>
                  newValue === null ? null : setValue('finAmo', newValue)
                }
                disabled={!modifySocialSecurity}
                renderInput={(props) => (
                  <TextField size="small" fullWidth {...props} />
                )}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={10} md={10}>
              <Divider>
                <Typography variant="body2">AMC</Typography>
              </Divider>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} md={12}>
              <FormControl
                fullWidth
                size="small"
                disabled={!modifySocialSecurity}
              >
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Type de Gestion
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  defaultValue="1"
                  {...register('gestion')}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Séparée"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Unique"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="AMC"
                {...register('amc')}
                fullWidth
                disabled={!modifySocialSecurity}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                label="Numéro d'adhérent"
                {...register('numeroAdherent')}
                fullWidth
                disabled={!modifySocialSecurity}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="Début AMC"
                value={debutAmc}
                onChange={(newValue) =>
                  newValue === null ? null : setValue('debutAmc', newValue)
                }
                disabled={!modifySocialSecurity}
                renderInput={(props) => (
                  <TextField size="small" fullWidth {...props} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="Fin AMC"
                value={finAmc}
                onChange={(newValue) =>
                  newValue === null ? null : setValue('finAmc', newValue)
                }
                disabled={!modifySocialSecurity}
                renderInput={(props) => (
                  <TextField size="small" fullWidth {...props} />
                )}
              />
            </Grid>
          </Grid>
        )}
        <Grid xs={12} spacing={2}>
          <Divider sx={{ marginTop: '35px' }}>
            <Chip
              label="Info web service"
              onClick={() => setWebServiceInfoDropDown(!webServiceInfoDropDown)}
              variant="outlined"
            />
          </Divider>
        </Grid>
        {webServiceInfoDropDown && (
          <Grid container item xs={12} spacing={2}>
            <Grid
              container
              spacing={2}
              style={{
                background: '#fff',
                borderRadius: '20px',
                marginTop: '10px',
                marginBottom: '0px',
                marginRight: 'px',
                marginLeft: '20px',
                padding: '0px 10px 10px 0px',
                width: '100%',
              }}
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'SocialSecurityNumber' })} :
                </Typography>
                <Typography variant="body1">{'answer'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'Plan' })} :
                </Typography>
                <Typography variant="body1">{'answer'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'Fund/Center' })} :
                </Typography>
                <Typography variant="body1">{'answer'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'ManagementCode' })} :
                </Typography>
                <Typography variant="body1">{'answer'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'AmoRights' })} :
                </Typography>
                <Typography variant="body1">{'answer'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'Coverages' })} :
                </Typography>
                <Typography variant="body1">{'answer'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'Beneficiary' })} :
                </Typography>
                <Typography variant="body1">{'answer'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'AmcRights' })} :
                </Typography>
                <Typography variant="body1">{'answer'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'Coverages' })} :
                </Typography>
                <Typography variant="body1">{'answer'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'MutualInsurance' })} :
                </Typography>
                <Typography variant="body1">{'answer'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        xs={12}
        sx={{
          paddingTop: '20px',
          marginRight: '24px',
          textAlign: 'right',
          marginBottom: '15px',
        }}
      >
        <Divider />
      </Grid>
      {modifyPatient ||
      modifyInsured ||
      modifyIns ||
      modifySocialSecurity ||
      modifyCarePathway ? (
          <Grid
            xs={12}
            sx={{
              paddingTop: '20px',
              marginRight: '24px',
              textAlign: 'right',
              marginBottom: '25px',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ color: 'white' }}
            >
            Envoyer
            </Button>
          </Grid>
        ) : null}
      <NirhistoryModal isOpen={isModalHistoryOpen} onClose={handleCloseModal} />
    </form>
  )
}

export default PatientForm
