export interface VitalCardDto {
  id: number
}

export interface OrganismeInfo {
  id: number
  type: number
  code: string
  regime: string
  caisse: string
  centre: string
  libelle: string
  codeDestinataire: string
  centreInfo: string
  teletransmissible: boolean
  active: boolean
}

export interface CouvertureDto {
  id: number
  numeroSerieCv: string
  beneficiaireNom: string
  beneficiairePrenom: string
  beneficiaireNir: string
  beneficiaireDateNaissance: string
  beneficaireNomUsuel: string
  assureNom: string
  assureNomUsuel: string
  assurePrenom: string
  assureNir: string
  rangNaissance: number
  qualite: number
  idOrganismeAmo: number
  organismeAmo: OrganismeInfo
  codeGestionAmo: string
  codeServiceAmo: string
  codeSituationAmo: string
  dateDebutAmo: string
  dateFinAmo: string
  idOrganismeAmc: number
  organismeAmc: OrganismeInfo
  dateDebutAmc: string
  dateFinAmc: string
  numeroAdherentAmc: string
  indicateurTraitementAmc: string
  codeRoutageAmc: string
  identifiantHoteAmc: string
  nomDomaineAmc: string
  codeAiguillageStsAmc: string
  idPatient: number
  idMedicalOrder: number
}

export interface CouvertureResponseDto {
  erreur: boolean
  erreurCode: string
  erreurMessage: string
  couverture: CouvertureDto
}

export interface QuotationDto {
  id: number
  code: string
  libelle: string
  codeRegroupement: string
  prixOptam: number
  prixNonOptam: number
  modificateurs: string
}

export enum WorklistTabsEnum {
  pendingRegistration = 'pendingRegistration',
  registered = 'registered',
  toQuote = 'toQuote',
  medicalOrderCancelled = 'medicalOrderCancelled',
  toInvoice = 'toInvoice',
}
