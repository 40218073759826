import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FindPatientsQuery, PatientDto } from '@services/api'
import { PaginatedDto } from '@services/extendedType'
import { PatientMergeHistoryDto } from '@services/PatientService'
import { updatePatient } from '@state/thunks/authThunk'
import {
  doFetchMergeHistory,
  fetchOnePatientById,
  fetchPatients,
  getCouverture,
} from '@state/thunks/patientsThunk'
import { CouvertureDto } from 'src/common/interfaces'

export type PatientsState = {
  patientsIds: number[]
  patients: PatientDto[]
  patientsFilter: FindPatientsQuery
  currentPatientId: number
  currentPatientInfo: PatientDto | null
  patientCouverture: CouvertureDto | null
  mergeHistories: PatientMergeHistoryDto[]
}

const initialState: PatientsState = {
  patientsIds: [],
  patients: [],
  currentPatientId: 0,
  currentPatientInfo: null,
  patientCouverture: null,
  patientsFilter: {
    limit: 20,
    page: 1,
    siteId: 1,
  },
  mergeHistories: [],
}

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    addPatient: (state, action: PayloadAction<PatientDto>) => {
      state.patientsIds.push(action.payload.id)
    },
    getPatient: (state, action: PayloadAction<number[]>) => {
      state.currentPatientId = action.payload[0]
      state.patientsIds = action.payload.map((patientId: number) => patientId)
      console.log('test ici2', state.patientsIds)
    },
    getPatientById: (state, action: PayloadAction<number>) => {
      state.currentPatientId = action.payload
      console.log('test ici1', state.patientsIds)
    },
    setCouverture: (state, action: PayloadAction<CouvertureDto | null>) => {
      state.patientCouverture = action.payload
    },
    setPatientsFilter: (state, action: PayloadAction<FindPatientsQuery>) => {
      state.patientsFilter = { ...state.patientsFilter, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchPatients.fulfilled,
      (state, { payload }: PayloadAction<PaginatedDto<PatientDto>>) => {
        state.patients = payload.datas
      },
    )
    builder.addCase(fetchOnePatientById.fulfilled, (state, action) => {
      state.currentPatientInfo = action.payload as PatientDto
    })
    builder.addCase(getCouverture.fulfilled, (state, action) => {
      state.patientCouverture = action.payload
    })
    builder.addCase(updatePatient.fulfilled, (state, action) => {
      state.currentPatientInfo = action.payload
    })
    builder.addCase(doFetchMergeHistory.fulfilled, (state, action) => {
      state.mergeHistories = action.payload
    })
  },
})

export const { addPatient, getPatient, setCouverture, setPatientsFilter } =
  patientsSlice.actions

export default patientsSlice.reducer
