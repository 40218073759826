import moment from 'moment'
import { axios, getConfigs, IRequestConfig } from './api'

export interface CardReaderDto {
  id: string
}

export async function getUrl(): Promise<CardReaderDto> {
  let url = `${process.env.REACT_APP_CARD_READER_URL}/lecteur/id`
  const res = await new Promise<{ id: string }>((resolve, reject) => {
    const configs: IRequestConfig = getConfigs(
      'get',
      'application/json',
      url,
      {},
    )

    axios(configs, resolve, reject)
  })
  return res
  const date = moment().format('YYYY-MM-DD')
  url = `${process.env.REACT_APP_RESIP_URL}/lecture/LectureCarte?idResip=1&dateLecteur=${date}&idLecteur=${res.id}`
  const cardReasRes = await new Promise<{
    url: string
    erreur: boolean
    erreurCode: string
    erreurMessage: string
  }>((resolve, reject) => {
    const configs: IRequestConfig = getConfigs(
      'get',
      'application/json',
      url,
      {},
    )

    axios(configs, resolve, reject)
  })
  if (cardReasRes.erreurCode) {
    throw new Error(cardReasRes.erreurMessage)
  }
  // return url
}
