import { combineReducers } from '@reduxjs/toolkit'

import authReducer from './authReducer'
import alertReducer from './alertReducer'
import loadingReducer from './loadingReducer'
import memberReducer from './memberReducer'
import clientReducer from './clientReducer'
import siteReducer from './siteReducer'
import testReducer from './testReducer'
import superAdminReducer from './superAdminReducer'
import logReducer from './logReducer'
import procedureReducer from './procedureReducer'
import doctorReducer from './doctorReducer'
import medicalEquipmentReducer from './medicalEquipmentReducer'
import roomReducer from './roomReducer'
import roleReducer from './roleReducer'
import itEquipmentReducer from './itEquipmentReducer'
import questionReducer from './questionReducer'
import ambulanceReducer from './ambulanceReducer'
import statsReducer from './statsReducer'
import medicalOrderReducer from './medicalOrderReducer'
import patientsReducer from './patientsReducer'
import billingReducer from './billingReducer'
import orderReducer from './orderReducer'

const appReducer = combineReducers({
  superAdmin: superAdminReducer,
  auth: authReducer,
  client: clientReducer,
  site: siteReducer,
  member: memberReducer,
  procedure: procedureReducer,
  examType: procedureReducer,
  doctor: doctorReducer,
  room: roomReducer,
  medicalEquipment: medicalEquipmentReducer,
  itEquipment: itEquipmentReducer,
  alert: alertReducer,
  loading: loadingReducer,
  log: logReducer,
  test: testReducer,
  role: roleReducer,
  question: questionReducer,
  ambulance: ambulanceReducer,
  stats: statsReducer,
  patients: patientsReducer,
  medicalOrder: medicalOrderReducer,
  billing: billingReducer,
  order: orderReducer,
})
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
export default rootReducer
