import DialogTitle from '@components/dialogs/DialogTitle'
import { DialogContent } from '@material-ui/core'
import { Grid } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import theme from '@utils/theme'
import { PatientForm } from './Form/PatientForm'
import { MedicalOrder } from '@services/api'
import { useEffect } from 'react'
import { useAppDispatch } from '@hooks/reduxHooks'
import { getCouverture } from '@state/thunks/patientsThunk'
import { vitalResponseMock } from '@utils/constants'
import moment from 'moment'
import { OrderDto } from '@services/dtos'

export interface PatientDialogProps {
  order: OrderDto
  onClose: () => void
}

export default function PatientDialog({ order, onClose }: PatientDialogProps) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const lectureDate = moment().format('YYYY-MM-DD')
    dispatch(
      getCouverture({
        dto: vitalResponseMock,
        medicalOrderId: order.id,
        lectureDate,
      }),
    )
  }, [order])

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{
        style: {
          background: '#E2E6F2',
        },
      }}
      fullScreen
    >
      <DialogTitle
        onClose={onClose}
        title="Fiche Patient"
        style={{
          background: '#fff',
          color: theme.palette.primary.main,
        }}
      />
      <DialogContent style={{ height: '100%', overflowY: 'hidden' }}>
        <Grid container style={{ height: '100%' }}>
          <Grid
            item
            xs={4}
            sx={{
              height: '100%',
              border: 0,
              borderRight: 1,
              borderRightColor: '#C6CAD5',
              marginTop: '15px',
              overflowY: 'auto',
            }}
          >
            <PatientForm />
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              height: '100%',
              marginTop: '15px',
              marginBottom: '15px',
              paddingLeft: '24px',
              overflowY: 'auto',
            }}
          ></Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
