import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { debounce } from 'lodash'
import React from 'react'

interface Option<T> {
  value: T
  label: string
}

interface Props<T> {
  value?: Option<T> | null
  onChange: (value: T | null) => void
  options: Option<T>[]
  handleSearch: (search: string) => Promise<void>
  label?: string
  getValue: (option: T) => string | number
  size?: 'small' | 'medium'
  ref?: React.RefObject<HTMLDivElement>
}

export const AsyncAutoComplete = <T,>({
  value,
  onChange,
  options,
  handleSearch,
  label,
  size,
  ref,
}: Props<T>) => {
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const search = React.useMemo(
    () =>
      debounce((value: string) => {
        setIsLoading(true)
        handleSearch(value).finally(() => {
          setIsLoading(false)
        })
      }, 300),
    [handleSearch],
  )

  return (
    <Autocomplete
      ref={ref}
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      value={value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={isLoading}
      onInputChange={(_, value) => {
        search(value)
      }}
      blurOnSelect={true}
      onChange={(_, value) => {
        onChange(value?.value || null)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      size={size}
    />
  )
}

export default AsyncAutoComplete
