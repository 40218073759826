import { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import Drawer from '@components/Drawer'
import { Avatar, Backdrop, styled } from '@mui/material'
import Logo from '../assets/img/logos/logo.png'
import { Actions } from '@state/actions'

import { ClientDto, AccountDto, AccountRoleEnum } from '@services/api'
import { findMyInfos } from '@state/thunks/authThunk'
import { useSelector } from '@state/store'

const StyledAvatar = styled(Avatar)(() => ({
  animation: '$animation 2s ease-in-out alternate infinite',
  '@keyframes animation': {
    from: {
      transform: 'scale(1)',
    },
    to: {
      transform: 'scale(2)',
    },
  },
}))
const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}))
const StyledMain = styled('main')(() => ({
  flex: 1,
  paddingLeft: isMobileOnly ? '' : 58,
  paddingTop: 2.5,
}))

// const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']
export default function AppLayout() {
  const dispatch = useDispatch()
  const [sidebar, setSidebar] = useState<boolean>(false)
  // const [open, setOpen] = useState(false)
  const { isAuth, loading, account } = useSelector(
    ({ auth, client, loading }) => ({
      isAuth: auth.isAuth,
      account: auth.account as AccountDto,
      client: client.selected as ClientDto,
      profile: auth.account as AccountDto,
      loading: loading[Actions.FIND_MY_INFOS],
    }),
  )

  // let logoutTimeout: NodeJS.Timeout

  // const clearTimeOut = () => {
  //   if (logoutTimeout) clearTimeout(logoutTimeout)
  // }

  // const setTimeOut = () => {
  //   if (account)
  //     logoutTimeout = setTimeout(
  //       () => setOpen(true),
  //       account.security.inactivityTimeout * 60 * 1000 || 1000,
  //     )
  // }

  // const resetTimeout = () => {
  //   clearTimeOut()
  //   setTimeOut()
  // }

  // const handleLogout = () => {
  //   clearTimeOut()
  //   dispatch(logout())
  // }
  // const handleStayLogged = () => {
  //   resetTimeout()
  //   setOpen(false)
  // }

  useEffect(() => {
    if (isAuth) dispatch(findMyInfos())
  }, [])

  // useEffect(() => {
  //   for (const i in events) {
  //     window.addEventListener(events[i], resetTimeout)
  //   }
  //   setTimeOut()
  //   return () => {
  //     clearTimeOut()
  //     for (const i in events) {
  //       window.removeEventListener(events[i], resetTimeout)
  //     }
  //   }
  // })

  if (!isAuth) return <Navigate to={{ pathname: '/auth/login' }} />

  if (loading)
    return (
      <StyledBackdrop open={true}>
        <StyledAvatar src={Logo} alt="logo" />
      </StyledBackdrop>
    )
  return (
    <>
      <Drawer
        toggleDrawer={setSidebar}
        open={sidebar}
        role={account.role as AccountRoleEnum}
      />
      <StyledMain>
        <Outlet />
      </StyledMain>
      {/* <AutoLogoutDialog
        open={open}
        onClose={() => setOpen(false)}
        handleStayLogged={handleStayLogged}
        handleLogout={handleLogout}
      /> */}
    </>
  )
}
