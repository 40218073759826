import { Button } from '@components/buttons'
import { Text } from '@components/texts'
import DialogTitle from '@components/dialogs/DialogTitle'
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { DataGridPro, GridColumns, GridRowId } from '@mui/x-data-grid-pro'
import { deleteType } from '@state/thunks/procedureThunk'
import theme from '@utils/theme'
import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import PatientDialog from './PatientDialog'
import PatientFusionDialog from './DialogPatientFusion'
import { RegisterPatientForm } from '../billing/form/RegisterPatientForm'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { findMedicalOrders } from '@state/thunks/medicalOrderThunk'
import {
  setMedicalOrderFilters,
  setSelectedMedicalOrder,
} from '@state/reducers/medicalOrderReducer'
import { capitalize } from 'lodash'
import { MedicalOrderToolbar } from '@components/tables/Toolbars/MedicalOrderToolbar'
import { MedicalOrder } from '@services/api'
import MedicalOrderDialog from './MedicalOrderDialog'
import { doFindOrders, setSelectedOrder } from '@state/reducers/orderReducer'
import { OrderDto } from '@services/dtos'

const useStyles = makeStyles(() => ({
  action: {
    cursor: 'pointer',
    padding: 6,
    margin: '2px 0px',
    '&:hover': {
      backgroundColor: 'rgb(236,240,251)',
    },
  },
}))

export interface DialogProps {
  openPatientDetails: boolean
}

export default function Registration(props) {
  const dispatch = useAppDispatch()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [openWithCV, setOpenWithCV] = useState(false)

  const [dialogAction, setDialogAction] = useState<
    'PatientDetails' | 'PatientForm' | 'patientFusion' | 'MedicalOrder' | null
  >(null)

  const classes = useStyles()

  const { selectedOrder, filters, orders } = useAppSelector((state) => ({
    medicalOrders: state.order.orders,
    filters: state.order.filters,
    selectedOrder: state.order.selectedOrder,
    orders: state.order.orders,
  }))

  const actionBtnObj = [
    {
      id: 1,
      btnName: 'Accueillir le patient',
      icon: 'fas fa-door-open',
      action: (row: OrderDto) => handleRegisterPatientClick(row),
      actionKey: 'registerPatient',
    },
    {
      id: 2,
      btnName: 'Accueillir le patient avec Carte Vitale',
      icon: 'fas fa-id-card',
      onOverString: 'ceci est une mini description du btn',
      actionKey: 'registerPatientWithCV',
      action: () => handleClickActionPatientWelcomeWithCard(),
    },
    {
      id: 3,
      btnName: 'Consulter la demande',
      actionKey: 'viewMO',
      icon: 'fas fa-eye',
      onOverString: 'ceci est une mini description du btn',
      action: () => handleClickActionLookAtMO(),
    },
    {
      id: 4,
      btnName: 'Modifier la demande',
      actionKey: 'updateMO',
      icon: 'fas fa-edit',
      onOverString: 'ceci est une mini description du btn',
      action: () => handleClickActionUpdateMO(),
    },
    {
      id: 5,
      btnName: 'Fiche patient',
      actionKey: 'patientDetails',
      icon: 'fas fa-user',
      action: (row: OrderDto) => {
        handlePatientDetailsClick()
        dispatch(setSelectedOrder(row))
      },
    },
    {
      id: 6,
      btnName: 'Fusionner le patient',
      actionKey: 'patientMerge',
      icon: 'fas fa-users-cog',
      action: (row: OrderDto) => {
        handleClickActionPatientMerge()
        dispatch(setSelectedOrder(row))
      },
    },
    {
      id: 7,
      btnName: 'Voir facture',
      actionKey: 'patientBill',
      icon: 'fas fa-file-invoice-dollar',
      action: (row: OrderDto) => handleClickActionPatientBill(row),
    },
    {
      id: 7,
      btnName: 'Voir',
      actionKey: 'ViewMo',
      icon: 'fas fa-eye',
      action: (row: OrderDto) => {
        handleMedicalOrderView()
        dispatch(setSelectedOrder(row))
      },
    },
  ]

  const handleRegisterPatientClick = (medicalOrder: OrderDto) => {
    setDialogAction('PatientForm')
    setOpenWithCV(false)
  }
  const handleClickActionPatientWelcomeWithCard = () => {
    setOpenWithCV(true)
  }
  const handleClickActionLookAtMO = () => {}
  const handleClickActionUpdateMO = () => {}
  const handlePatientDetailsClick = () => {
    setDialogAction('PatientDetails')
  }
  const handleClickActionPatientMerge = () => {
    setDialogAction('patientFusion')
  }

  const handleMedicalOrderView = () => {
    setDialogAction('MedicalOrder')
  }

  const handleClickActionPatientBill = (mo: OrderDto) => {
    console.log(mo)
  }

  useEffect(() => {
    setIsLoading(true)
    // dispatch(findMedicalOrders({}))
    //   .unwrap()
    //   .finally(() => {
    //     setIsLoading(false)
    //   })
    dispatch(doFindOrders()).finally(() => {
      setIsLoading(false)
    })
  }, [filters.page, filters.search])

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'N° d\'examen',
      width: 160,
    },
    {
      field: 'patient.firstName',
      headerName: 'Prénom',
      width: 160,
      valueGetter: (params) => capitalize(params.row.patient.firstName),
    },
    {
      field: 'patient.lastName',
      headerName: 'Nom',
      width: 160,
      valueGetter: ({ row }) => {
        return capitalize(row.patient.lastName)
      },
    },
    {
      field: 'patient.birthDate',
      headerName: 'Date de naissance',
      width: 160,
      valueGetter: ({ row }) => {
        return moment(row.patient.birthDate).format('DD/MM/YYYY')
      },
    },
    {
      field: 'exam.label',
      headerName: 'Examen',
      width: 260,
      valueGetter: ({ row }) => {
        return `${capitalize(row.exam.label)} (${row.exam.code})`
      },
    },

    {
      field: 'plannedDate',
      headerName: 'Heure RDV',
      width: 150,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {moment(params.row.plannedDate).format('DD/MM/YYYY HH:mm')}
          </div>
        )
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      sortable: false,
      renderCell: (params) => {
        const medicalOrder = params.row
        return (
          <div
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {actionBtnObj.reduce((acc, el) => {
              if (props.actions.includes(el.actionKey)) {
                acc.push(
                  <Tooltip
                    key={medicalOrder.id + '-' + el.btnName}
                    title={el.btnName}
                  >
                    <Typography
                      className={classes.action}
                      onClick={() => {
                        const action = el.action.bind({})
                        action(medicalOrder)
                      }}
                    >
                      <Icon
                        className={el.icon}
                        sx={{ paddingRight: 2, color: '#3F3F3F' }}
                        fontSize="small"
                      />
                    </Typography>
                  </Tooltip>,
                )
              }
              return acc
            }, new Array<React.ReactElement>())}
          </div>
        )
      },
    },
  ]

  const onRefresh = useCallback(() => {
    setIsLoading(true)
    dispatch(findMedicalOrders({})).finally(() => {
      setIsLoading(false)
    })
  }, [dispatch])

  const _onDeleteConfirm = () => {
    dispatch(deleteType(selectedRows))
    setIsDeleteDialogOpen(false)
  }

  const [stateSearch, setStateSearch] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    if (searchTerm === '') {
      setStateSearch(false)
    }
    if (searchTerm !== '') {
      setStateSearch(true)
    }
  }, [searchTerm, setSearchTerm])

  return (
    <Container maxWidth={false} style={{ height: '100%', padding: '0px 0px' }}>
      <Grid container spacing={4} style={{ height: '96vh' }}>
        <Grid item container>
          <Paper
            elevation={2}
            style={{
              width: '100%',
              borderRadius: '0px 25px 25px 25px',
            }}
          >
            <Grid container spacing={4} style={{ height: '95vh' }}>
              <Grid item container>
                <DataGridPro
                  density="standard"
                  style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '0px 25px 25px 25px',
                  }}
                  columns={columns}
                  rows={orders}
                  rowCount={filters.total}
                  disableSelectionOnClick
                  pagination
                  paginationMode="server"
                  disableMultipleSelection
                  onPageChange={(page) => {
                    dispatch(setMedicalOrderFilters({ ...filters, page }))
                  }}
                  initialState={{
                    pinnedColumns: { left: ['actions'], right: [''] },
                  }}
                  loading={isLoading}
                  components={{
                    Toolbar: MedicalOrderToolbar,
                    LoadingOverlay: LinearProgress,
                  }}
                  componentsProps={{
                    toolbar: {
                      onRefresh,
                    },
                  }}
                />
              </Grid>
            </Grid>
            {/* add dialog title with close button */}
          </Paper>
          {stateSearch ? (
            <Box
              sx={{
                position: 'absolute',
                top: '40%',
                left: '45%',
                transform: 'translate(-50%, -50%)',
                height: '500px',
                width: '20%',
              }}
            >
              <Paper elevation={5}>
                <Box
                  sx={{
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'sticky',
                    p: 1,
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  <Typography variant="h6" color="initial">
                    Recherche patient
                  </Typography>
                  <Icon
                    fontSize="large"
                    className="fas fa-times-circle"
                    onClick={() => {
                      setStateSearch(false), setSearchTerm('')
                    }}
                    sx={{ color: '#E91E63', cursor: 'pointer' }}
                  />
                </Box>
              </Paper>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      {dialogAction === 'PatientForm' && selectedOrder && (
        <Dialog
          open={true}
          onClose={() => {
            setDialogAction(null)
          }}
          maxWidth="lg"
          aria-labelledby="form-dialog-title2"
          PaperProps={{
            sx: {
              borderRadius: 5,
              background: theme.palette.primary.main,
            },
          }}
        >
          <DialogTitle
            onClose={() => {
              setDialogAction(null)
            }}
            title="Accueillir le patient"
            style={{
              background: '#fff',
            }}
          />
          <DialogContent sx={{ bgcolor: '#E2E6F2' }}>
            <RegisterPatientForm
              onClose={() => {
                setDialogAction(null)
              }}
              mode="add"
              openWithCV={openWithCV}
              patientId={selectedOrder.patient.id}
            />
          </DialogContent>
        </Dialog>
      )}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: theme.palette.primary.main,
          },
        }}
      >
        <DialogTitle
          onClose={() => setIsDeleteDialogOpen(false)}
          title="deleteReception"
          style={{
            background: '#fff',
            color: theme.palette.primary.main,
          }}
          format
        />
        <DialogContent
          sx={{
            background: '#E2E6F2',
            minWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Text
            text="deleteConfirmation"
            variant="h5"
            color="secondary"
            sx={{ fontWeight: 'bold', paddingTop: '20px' }}
            format
          />
        </DialogContent>
        <DialogActions
          sx={{
            background: '#fff',
          }}
        >
          <Button
            fullWidth
            textColor="white"
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
            text="Cancel"
          />
          <Button
            fullWidth
            textColor="white"
            onClick={_onDeleteConfirm}
            color="primary"
            text="Save"
          />
        </DialogActions>
      </Dialog>
      {dialogAction === 'PatientDetails' && selectedOrder && (
        <PatientDialog
          order={selectedOrder}
          onClose={() => setDialogAction(null)}
        />
      )}
      {dialogAction === 'patientFusion' && selectedOrder && (
        <PatientFusionDialog
          patientId={selectedOrder.patient.id}
          open
          onClose={() => setDialogAction(null)}
        />
      )}
      {selectedOrder && (
        <MedicalOrderDialog
          isOpen={dialogAction === 'MedicalOrder'}
          onClose={() => setDialogAction(null)}
          order={selectedOrder}
        />
      )}
    </Container>
  )
}
