import { useEffect, useState, useCallback } from 'react'
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormHelperText,
  Checkbox,
  Button,
  DialogActions,
  Tooltip,
  Icon,
  Dialog,
  DialogContent,
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import 'moment/locale/fr'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import ReactAutocomplete from 'react-autocomplete'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { RppsDto, SexEnum } from '@services/api'
import { formatSsn, formatSsnPrefix } from '@utils/ssnUtil'
import { RppsAutocompleteByNameInput } from '@components/RppsAutocompleteByNameInput'
import { DatePicker } from '@mui/lab'
import { BanService } from '@services/BanServices'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import cartevital from '@assets/img/cartevital.png'
import logoInsi from '@assets/img/logoINSi.png'
import DialogTitle from '@components/dialogs/DialogTitle'
import NirhistoryModal from '../NirhistoryModal'
import { patientFormSchema } from '@utils/schemas'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { fetchOnePatientById } from '@state/thunks/patientsThunk'
import { updatePatient } from '@state/thunks/authThunk'
import { findMedicalOrders } from '@state/thunks/medicalOrderThunk'
import { enqueueSnackbar } from '@state/reducers/alertReducer'

type PatientInit = {
  id: string
  sex: SexEnum
  firstName: string
  lastName: string
  maidenName: string
  birthDate: Date
  phoneNumber: string
  email: string
  address: string
  birthPlace: string
  birthPlaceDepartment: string
  ssn: string
  weight: number
  height: number
  familyDoctor: RppsDto
  comments: string
  iod: string
  ins: string
}

type FormAddPatientProps = {
  onClose: () => void
  mode: 'add' | 'edit'
  patientObj?: any // TODO: add type PatientDto
  openWithCV?: boolean
  patientId?: number
}

// TODO rename this component
export const RegisterPatientForm = ({
  onClose,
  mode,
  openWithCV,
  patientId,
}: FormAddPatientProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<PatientInit>({
    resolver: yupResolver(patientFormSchema),
  })

  const [open, setOpen] = useState(false)
  const [erreurMessage, setErreurMessage] = useState('')
  const [erreurCode, setErreurCode] = useState('')
  const [codeRetour, setCdeRetour] = useState('')
  const [openIframe, setOpenIframe] = useState(false)
  const [lecteurId, setLecteurId] = useState(0)
  const date = moment(new Date()).format('YYYY/DD/MM')

  const clearErrosMessage = () => {
    setErreurMessage('')
    setErreurCode('')
    setCdeRetour('')
  }

  const [ssn, setSsn] = useState('')
  const [ssnPrefix, setSsnPrefix] = useState('')
  const [ssnStatus, setSsnStatus] = useState(false)

  const [sex, setSex] = useState('M')
  let birthDate = watch('birthDate')

  const [isManualAddressVisible, setIsManualAddressVisible] = useState(false)
  const [addressSearch, setAddressSearch] = useState('')
  const [addressOptions, setAddressOptions] = useState([
    { label: '', value: '' },
  ])

  const [isManualBirthPlaceVisible, setIsManualBirthPlaceVisible] =
    useState(false)
  const [birthPlaceSearch, setBirthPlaceSearch] = useState('')
  const [birthPlace, setBirthPlace] = useState('')
  const [birthPlaceExtra, setBirthPlaceExtra] = useState('')
  const [birthPlaceOptions, setBirthPlaceOptions] = useState([
    { label: '', value: '' },
  ])

  const ins = watch('ins')
  const phoneNumber = watch('phoneNumber')
  const address = watch('address')
  const familyDoctor = watch('familyDoctor')

  const dispatch = useAppDispatch()
  const patient = useAppSelector(({ patients }) => patients.currentPatientInfo)
  const intl = useIntl()

  const [isModalHistoryOpen, setIsModalHistoryOpen] = useState(false)

  const handleCloseModal = () => {
    setIsModalHistoryOpen(false)
  }

  const BanServices = new BanService()

  const [loading, setLoading] = useState(false)

  const useStyles = makeStyles({
    phoneInput: {
      '& .PhoneInputInput': {
        backgroundColor: 'transparent',
        border: 'none',
        textColor: 'black',
        height: '100%',
      },
    },
  })

  const editModeEngaged = () => {
    if (mode === 'edit') {
      setValue('sex', patient?.sex || SexEnum.M)
      setValue('firstName', patient?.firstName || '')
      setValue('lastName', patient?.lastName || '')
      setValue('maidenName', patient?.surName || '')
      setValue('phoneNumber', patient?.phoneNumber || '')
      setValue('email', patient?.email || '')
      setValue('address', patient?.address || '')
      setIsManualAddressVisible(true)
      birthDate = new Date(patient?.birthDate || '')
      setValue('birthDate', new Date(patient?.birthDate || ''))
      setValue('birthPlace', patient?.placeOfBirth || '')
      if (patient?.placeOfBirth === undefined && patient?.ssn !== undefined) {
        setValue('birthPlace', ' ')
        setBirthPlaceExtra(patient?.ssn.slice(5, 7) + '000')
      }
      setSsnPrefix(patient?.ssn.slice(0, 7) || '')
      setValue('ssn', patient?.ssn.slice(7, 15) || '')
      setValue('weight', patient?.weight || 0)
      setValue('height', patient?.height || 0)
      // setValue('comments', patient?.comments || '')
      setIsManualBirthPlaceVisible(true)
    }
  }
  useEffect(() => {
    if (!patient) {
      return
    }
    setValue('sex', patient?.sex || SexEnum.M)
    setValue('firstName', patient?.firstName || '')
    setValue('lastName', patient?.lastName || '')
    setValue('maidenName', patient?.surName || '')
    setValue('phoneNumber', patient?.phoneNumber || '')
    setValue('email', patient?.email || '')
    setValue('address', patient?.address || '')
    setIsManualAddressVisible(true)
    birthDate = new Date(patient?.birthDate || '')
    setValue('birthDate', new Date(patient?.birthDate || ''))
    setValue('birthPlace', patient?.placeOfBirth || '')
    if (patient?.placeOfBirth === undefined && patient?.ssn !== undefined) {
      setValue('birthPlace', ' ')
      setBirthPlaceExtra(patient?.ssn.slice(5, 7) + '000')
    }
    setSsnPrefix(patient?.ssn.slice(0, 7) || '')
    setValue('ssn', patient?.ssn.slice(7, 15) || '')
    setValue('weight', patient?.weight || 0)
    setValue('height', patient?.height || 0)
    setValue('familyDoctor', patient?.familyDoctor as any)
    // setValue('comments', patient?.comments || '')
    setIsManualBirthPlaceVisible(true)
  }, [patient])

  useEffect(() => {
    editModeEngaged()
    openWithCV ? setOpenIframe(true) : null
  }, [])

  console.log(patientId)
  useEffect(() => {
    if (!patientId) {
      return
    }
    dispatch(fetchOnePatientById(patientId))
  }, [dispatch, patientId])

  const onSubmit = (data: PatientInit) => {
    if (!patientId) {
      return
    }
    dispatch(
      updatePatient({
        id: patientId,
        body: {
          firstName: data.firstName,
          lastName: data.lastName,
          surName: data.maidenName,
          sex: data.sex,
          birthDate: data.birthDate,
          address: data.address,
          placeOfBirth: data.birthPlace,
          phoneNumber: data.phoneNumber,
          email: data.email,
          weight: data.weight,
          height: data.height,
          ssn: formatSsn(ssnPrefix + data.ssn),
          familyDoctor: data.familyDoctor,
        },
      }),
    ).then(() => {
      dispatch(findMedicalOrders({}))
      onClose()
      dispatch(
        enqueueSnackbar({
          message: intl.formatMessage({
            id: 'notifications.patient-update-success',
          }),
          options: { variant: 'success' },
        }),
      )
    })
  }
  const classes = useStyles()

  console.log(errors)

  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      BanServices.searchFullAddress(searchTerm)
        .then((data) => {
          if (data.length > 0) {
            const storeData = data.map((d) => ({
              label: d.place,
              value: d.id,
            }))
            setAddressOptions(storeData)
          }
        })
        .catch((error) => {
          console.error('Failed to fetch addresses:', error)
        })
    }, 500),
    [],
  )

  useEffect(() => {
    setValue('birthPlaceDepartment', birthPlaceExtra)
    setValue('birthPlace', birthPlace)
  }, [birthPlace, birthPlaceExtra, setValue])

  useEffect(() => {
    debouncedSearch(addressSearch)
  }, [addressSearch, debouncedSearch])

  const setIsManualBirthPlaceVisibleAndRemouve = (checked: boolean) => {
    setIsManualBirthPlaceVisible(checked)
    if (!checked) {
      setBirthPlaceExtra('')
    }
  }

  // add debounce 'lodash' to the search 500ms after the last key stroke to call the api

  const debouncedSearchBirth = useCallback(
    debounce((searchTerm) => {
      BanServices.search(searchTerm)
        .then((data) => {
          if (data.length > 0) {
            const storeData = data.map((d) => ({
              label: d.place,
              value: d.id,
            }))
            setBirthPlaceOptions(storeData)
          }
        })
        .catch((error) => {
          console.error('Failed to fetch addresses:', error)
        })
    }, 500),
    [],
  )

  useEffect(() => {
    debouncedSearchBirth(birthPlaceSearch)
  }, [birthPlaceSearch, debouncedSearchBirth])

  useEffect(() => {
    if (
      birthDate &&
      birthPlace &&
      sex &&
      (birthPlace.slice(0, 5).match(/^\d{5}$/) ||
        (birthPlaceExtra &&
          birthPlaceExtra.match(/^\d{5}$/) &&
          birthPlaceExtra.length === 5) ||
        (birthPlaceExtra === '99' && birthPlaceExtra.length === 2))
    ) {
      setSsnStatus(true)
    } else {
      setSsnPrefix('')
      setSsnStatus(false)
    }

    if (ssnStatus) {
      const gender = sex === 'M' ? '1' : '2'
      const birthMonth = birthDate === null ? null : birthDate.getMonth() + 1
      const birthMonth2 =
        birthMonth === null
          ? null
          : birthMonth < 10
            ? '0' + birthMonth
            : birthMonth
      const birthYear =
        birthDate === null
          ? null
          : birthDate.getFullYear().toString().slice(2, 4)
      const department = birthPlace.slice(0, 2)
      const departmentByExtra = birthPlaceExtra?.slice(0, 2)
      if (!isManualBirthPlaceVisible) {
        setSsnPrefix(gender + birthYear + birthMonth2 + department)
      } else {
        setSsnPrefix(gender + birthYear + birthMonth2 + departmentByExtra)
      }
    }
  }, [patient])

  const onAddressChange = (value: string) => {
    const addr = value.replace(/\s/g, '-')
    setAddressSearch(addr)
    setValue('address', `${address}`)
  }

  const onBirthPlaceChange = (value: string) => {
    const addr = value.replace(/\s/g, '-')
    setBirthPlaceSearch(addr)
    setValue('birthPlace', addr)
  }

  const [insiValidate, setInsiValidate] = useState(false)

  //
  // Récupération des données de la carte vital

  const callInsiACV = () => {
    setLoading(true)
    axios
      .post('http://localhost:5162/insi/rechercheAvecCarteVitale', mockupACV, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        console.log(response)
        if (response.status === 200 && response.data.erreur === false) {
          setInsiValidate(true)
          setValue('firstName', response.data.listePrenoms)
          setValue('maidenName', response.data.nomNaissance)
          setLoading(false)
          setValue('ins', response.data.ins)
          setValue('iod', response.data.oiD_INS)
        } else {
          setInsiValidate(false)
          setLoading(false)
          console.error('Failed to fetch INSI ACV:', response)
          setErreurCode('404')
          setErreurMessage('Failed to fetch insi')
          setCdeRetour('ADD09-D8B')
          setOpen(true)
        }
      })
      .catch((error) => {
        setInsiValidate(false)
        setLoading(false)
        setErreurCode('404')
        setErreurMessage('Failed to fetch insi')
        setCdeRetour('ADD09-D8B')
        setOpen(true)
        console.error('Failed to fetch INSI ACV:', error)
      })
  }

  const mockupACV = {
    psModel: {
      psNumeroFacturation: '2451702831',
      psIdentStructure: '44461871400026',
      authentificationMode: 0,
      codePorteurCPS: '1234',
    },
    insCV: {
      beneficiaireDateNaissance: '1977-07-14',
      beneficiaireRang: '1',
      beneficiaireNom: 'DE VINCI',
      beneficiaireNumSecu: '',
      assureNumSecu: '2770763220829',
      assureNomUsage: 'DE VINCI',
      numeroSerieCV: '468931920',
    },
  }

  const changeDataACV = () => {
    setValue('lastName', 'DE VINCI')
    setSex('F')
    setIsManualBirthPlaceVisible(true)
    birthDate = new Date('1977-07-14')
    setValue('birthDate', new Date('1977-07-14'))
    setBirthPlaceExtra('63000')
    setValue('ins', '2770763220829')
    setValue('iod', '1.2.250.1.213.1.4.8')
    setBirthPlace('Arlanc')
    setSsnPrefix('2770763')
    setValue('ssn', '220829')
    setLoading(true)
    callInsiACV()
  }

  //
  // Recherche avec Traits

  const callInsiSCV = () => {
    setLoading(true)
    axios
      .post('http://localhost:5162/insi/rechercheSansCarteVitale', mockupSCV, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        console.log(response)
        if (response.status === 200 && response.data.erreur === false) {
          setLoading(false)
          setInsiValidate(true)
          setSsnPrefix(response.data.ins.slice(0, 7))
          setValue('ssn', response.data.ins.slice(7, 13))
          setBirthPlace(response.data.lieuNaissance)
        } else {
          setLoading(false)
          setInsiValidate(false)
          setErreurCode('404')
          setErreurMessage('Failed to fetch insi')
          setCdeRetour('ADD09-D8B')
          setOpen(true)
          console.error('Failed to fetch INSI SCV:', response)
        }
      })
      .catch((error) => {
        setLoading(false)
        setInsiValidate(false)
        setErreurCode('404')
        setErreurMessage('Failed to fetch insi')
        setCdeRetour('ADD09-D8B')
        setOpen(true)
        console.error('Failed to fetch INSI SCV:', error)
      })
  }

  const mockupSCV = {
    psModel: {
      psNumeroFacturation: '2451702831',
      psIdentStructure: '44461871400026',
      authentificationMode: 0,
      codePorteurCPS: '1234',
    },
    insTraits: {
      beneficiaireNom: 'D\'ARTAGNAN DE L\'HERAULT',
      beneficiaireSexe: 'M',
      beneficiaireDateNaissance: '2001-06-17',
      beneficiaireLieu: '',
      beneficiairePrenoms: ['Pierre-Alain'],
      beneficiaireRang: '1',
      beneficiaireNumSecu: '',
      assureNumSecu: '',
    },
  }

  //
  // Vérification de l'insi
  const mockupRecuperation = {
    psModel: {
      psNumeroFacturation: '2451702831',
      psIdentStructure: '44461871400026',
      authentificationMode: 0,
      codePorteurCPS: '1234',
    },
    insPatient: {
      assureNumSecu: '',
      beneficiaireNom: 'D\'ARTAGNAN DE L\'HERAULT',
      beneficiaireNomUsage: '',
      beneficiairePrenom: 'PIERRE-ALAIN GUNTHER',
      beneficiaireSexe: 'M',
      beneficiaireLieuNaissance: '63220',
      beneficiaireDateNaissance: '2001-06-17',
      beneficiaireRang: '1',
      beneficiaireNumSecu: '',
      oid: '1.2.250.1.213.1.4.8',
      insaVerifier: '101066322075460',
    },
  }

  const callInsiVerification = () => {
    setLoading(true)
    axios
      .post(
        'http://localhost:5162/insi/verification',
        { mockupRecuperation },
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then((response) => {
        console.log(response)
        if (
          response.status === 200 &&
          response.data.erreurMessage === 'INS OK'
        ) {
          setInsiValidate(true)
        } else {
          setInsiValidate(false)
          setErreurCode('404')
          setErreurMessage('Failed to fetch insi')
          setCdeRetour('ADD09-D8B')
          setOpen(true)
          // TODO: handle error
        }
      })
      .catch((error) => {
        setInsiValidate(true)
        setErreurCode('404')
        setErreurMessage('Failed to fetch insi')
        setCdeRetour('ADD09-D8B')
        setOpen(true)
        console.error('Failed to fetch addresses:', error)
      })
  }

  //
  // Fetch infos by carte vital

  const callInsiLecteurId = () => {
    axios
      .get('http://localhost:8888/lecteur/id')
      .then((response) => {
        console.log(response)
        if (response.status === 200) {
          console.log('Lecteur ID:', response.data.id)
          setOpenIframe(true)
          setLecteurId(response.data.id)
        } else {
          setInsiValidate(false)
          setErreurCode('404')
          setErreurMessage('Failed to fetch insi')
          setCdeRetour('ADD09-D8B')
          setOpen(true)
        }
      })
      .catch((error) => {
        // setInsiValidate(true)
        // setErreurCode('404')
        // setErreurMessage('Failed to fetch insi')
        // setCdeRetour('ADD09-D8B')
        // setOpen(true)
        // console.error('Failed to fetch addresses:', error)
      })
    setOpenIframe(true) // TODO: remouve for test
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} p={2}>
        {/* Civilité */}
        <Grid item xs={8}>
          <RadioGroup
            row
            {...register('sex')}
            aria-labelledby="sexe"
            defaultValue={'M'}
          >
            <FormControlLabel
              style={{ color: '#48bef9', fontWeight: 'bold' }}
              value="M"
              control={
                <Radio
                  sx={{
                    color: '#48bef9',
                    '&.Mui-checked': {
                      color: '#48bef9',
                    },
                  }}
                />
              }
              label="Mr"
            />
            <FormControlLabel
              value="F"
              style={{ color: '#f48dde', fontWeight: 'bold' }}
              control={
                <Radio
                  sx={{
                    color: '#f48dde',
                    '&.Mui-checked': {
                      color: '#f48dde',
                    },
                  }}
                />
              }
              label="Mme"
            />
          </RadioGroup>
        </Grid>
        <Grid
          xs={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="caption"
            sx={{ textAlign: 'center' }}
            fontSize={24}
            color={insiValidate ? 'success' : 'error'}
          >
            {loading ? (
              <CircularProgress
                size={28}
                sx={{ color: 'black', textAlign: 'left', marginLeft: '15px' }}
              />
            ) : insiValidate ? (
              'ISNI Validé'
            ) : (
              'ISNI Non Validé'
            )}
          </Typography>
        </Grid>
        {mode === 'add' ? (
          <Grid xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* image carte vital */}
            <Tooltip
              placement="top"
              title="récupérer les informations de la carte vital"
            >
              <Grid
                onClick={() => callInsiLecteurId()}
                sx={{
                  position: 'relative',
                  '::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '95%',
                  },
                  '&:hover::after': {
                    background: 'rgba(0, 0, 0, 0.1)',
                  },
                }}
              >
                <Icon
                  className="fas fa-search"
                  fontSize="large"
                  sx={{
                    color: '#3F3F3F',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
                <img src={cartevital} alt="carte vital" height={70} />
              </Grid>
            </Tooltip>
          </Grid>
        ) : null}
        {/* Prénom */}
        <Grid item xs={4}>
          <TextField
            size="small"
            label="Prénom"
            variant="outlined"
            {...register('firstName')}
            fullWidth
            inputProps={{ maxLength: 35 }}
          />
          {errors?.firstName && (
            <FormHelperText error>{errors?.firstName?.message}</FormHelperText>
          )}
        </Grid>
        {/* Nom */}
        <Grid item xs={4}>
          <TextField
            size="small"
            label="Nom"
            {...register('lastName')}
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 35 }}
          />
          {errors?.lastName && (
            <FormHelperText error>{errors?.lastName?.message}</FormHelperText>
          )}
        </Grid>
        {/* Nom de naissance */}
        <Grid item xs={4}>
          <TextField
            size="small"
            label="Nom de naissance"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 35 }}
            {...register('maidenName')}
          />
          {errors?.maidenName && (
            <FormHelperText error>{errors?.maidenName?.message}</FormHelperText>
          )}
        </Grid>
        {/* Date de naissance */}
        <Grid item xs={4}>
          <DatePicker
            value={moment(birthDate)}
            onChange={(ev) => {
              setValue('birthDate', ev ? ev.toDate() : new Date())
            }}
            inputFormat="DD/MM/YYYY"
            openTo="year"
            views={['year', 'month', 'day']}
            disableFuture
            label={
              <FormattedMessage
                id="birthDate"
                defaultMessage="Date de naissance"
              />
            }
            renderInput={(params) => (
              <TextField {...params} sx={{ width: '100%' }} />
            )}
          />
          {errors?.birthDate && (
            <FormHelperText error>{errors?.birthDate?.message}</FormHelperText>
          )}
        </Grid>
        {/* Numéro de téléphone */}
        <Grid item xs={4}>
          <PhoneInput
            name="phoneNumber"
            labels={{ number: 'Numéro de ' }}
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(v) => setValue('phoneNumber', v)}
            defaultCountry="FR"
            international
            style={{
              height: '34px',
              fontSize: '1em',
              border: '1px solid #ADB1BA',
              backgroundColor: 'transparent',
              borderRadius: '3px',
              padding: '10px',
            }}
            className={classes.phoneInput}
          />
          {errors?.phoneNumber && (
            <FormHelperText error>
              {errors?.phoneNumber?.message}
            </FormHelperText>
          )}
        </Grid>
        {/* Email */}
        <Grid item xs={4}>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            fullWidth
            {...register('email')}
          />
        </Grid>
        {/* Adresse */}
        <Grid item xs={6}>
          <Grid
            container
            sx={{
              paddingRight: '22px',
            }}
          >
            {!isManualAddressVisible && (
              <>
                <ReactAutocomplete
                  {...register('address')}
                  wrapperStyle={{
                    width: '100%',
                  }}
                  menuStyle={{
                    borderRadius: '3px',
                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                    background: '#fff',
                    padding: '10px',
                    fontSize: '1em',
                    position: 'fixed',
                    overflow: 'auto',
                    maxHeight: '50%',
                    zIndex: 100,
                  }}
                  inputProps={{
                    style: {
                      width: '100%',
                      height: '34px',
                      fontSize: '1em',
                      border: '1px solid #ADB1BA',
                      backgroundColor: 'transparent',
                      borderRadius: '3px',
                      padding: '10px',
                    },
                    disabled: isManualAddressVisible,
                    placeholder: 'Rechercher une addresse',
                  }}
                  getItemValue={(item) => item.label}
                  items={addressOptions}
                  renderItem={(item: any, isHighlighted: any) => (
                    <div
                      style={{
                        background: isHighlighted ? 'lightblue' : 'white',
                        cursor: 'pointer',
                        padding: '5px',
                      }}
                    >
                      {item.label}
                    </div>
                  )}
                  value={addressSearch}
                  onChange={(e) =>
                    setAddressSearch(e.target.value.replace(/\s/g, '-'))
                  }
                  onSelect={onAddressChange}
                />
                {errors?.address && (
                  <FormHelperText error>
                    {errors?.address?.message}
                  </FormHelperText>
                )}
              </>
            )}
          </Grid>

          {isManualAddressVisible && (
            <Grid container>
              <Grid xs={12}>
                <TextField
                  variant="outlined"
                  {...register('address')}
                  label={
                    <FormattedMessage id="adress" defaultMessage="Adresse" />
                  }
                  fullWidth
                  error={Boolean(errors?.address)}
                  helperText={errors?.address?.message}
                  defaultValue={address}
                  InputLabelProps={{ shrink: true }}
                  placeholder='Ex: "1 rue de la paix, 75000 Paris"'
                />
              </Grid>
              {errors?.address && (
                <FormHelperText error>
                  {errors?.address?.message}
                </FormHelperText>
              )}
            </Grid>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={isManualAddressVisible}
                onChange={(e) => setIsManualAddressVisible(e.target.checked)}
                color="primary"
              />
            }
            label={
              isManualAddressVisible
                ? 'Retour à la recherche'
                : 'Vous ne trouvez pas la bonne adresse ?'
            }
          />
        </Grid>
        {/* Poids */}
        <Grid item xs={3}>
          <TextField
            {...register('weight')}
            label="Poids"
            variant="outlined"
            type="number"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">kg</InputAdornment>
              ),
            }}
            inputProps={{
              min: 0,
              max: 400,
              inputMode: 'numeric',
            }}
          />
          {errors?.weight && (
            <FormHelperText error>{errors?.weight?.message}</FormHelperText>
          )}
        </Grid>
        {/* Taille */}
        <Grid item xs={3}>
          <TextField
            label="Taille"
            variant="outlined"
            type="number"
            fullWidth
            {...register('height')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">cm</InputAdornment>
              ),
            }}
            {...register('height')}
            inputProps={{
              min: 0,
              max: 300,
              inputMode: 'numeric',
            }}
          />
          {errors?.height && (
            <FormHelperText error>{errors?.height?.message}</FormHelperText>
          )}
        </Grid>
        {/* Lieu de naissance */}
        <Grid item xs={6}>
          <Grid
            container
            sx={{
              paddingRight: '22px',
            }}
          >
            {!isManualBirthPlaceVisible && (
              <>
                <ReactAutocomplete
                  menuStyle={{
                    borderRadius: '3px',
                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                    background: '#fff',
                    padding: '10px',
                    fontSize: '1em',
                    position: 'fixed',
                    overflow: 'auto',
                    maxHeight: '50%',
                    zIndex: 100,
                  }}
                  inputProps={{
                    style: {
                      width: '100%',
                      height: '34px',
                      fontSize: '1em',
                      border: '1px solid #ADB1BA',
                      backgroundColor: 'transparent',
                      focus: 'black',
                      borderRadius: '3px',
                      padding: '10px',
                    },
                    hover: {
                      border: '1px solid #000000de',
                    },
                    disabled: isManualBirthPlaceVisible,
                    placeholder: 'Lieu de naissance',
                  }}
                  wrapperStyle={{ width: '100%' }}
                  getItemValue={(item) => item.label}
                  items={birthPlaceOptions}
                  renderItem={(item: any, isHighlighted: any) => (
                    <div
                      style={{
                        background: isHighlighted ? 'lightblue' : 'white',
                        cursor: 'pointer',
                        padding: '5px',
                      }}
                    >
                      {item.label}
                    </div>
                  )}
                  value={birthPlaceSearch}
                  onChange={(e) =>
                    setBirthPlaceSearch(e.target.value.replace(/\s/g, '-'))
                  }
                  onSelect={onBirthPlaceChange}
                />
              </>
            )}
          </Grid>
          {isManualBirthPlaceVisible && (
            <Grid container spacing={1} justifyItems="space-between">
              <Grid item xs={8}>
                <TextField
                  size="small"
                  {...register('birthPlace')}
                  variant="outlined"
                  label={
                    <FormattedMessage
                      id="birthPlace"
                      defaultMessage="Lieu de naissance"
                    />
                  }
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  placeholder='Ex: "Paris"'
                  inputProps={{ maxLength: 35 }}
                />
              </Grid>
              {errors?.birthPlace && (
                <FormHelperText error>
                  {errors?.birthPlace?.message}
                </FormHelperText>
              )}
              <Grid item xs={4}>
                <TextField
                  size="small"
                  {...register('birthPlaceDepartment')}
                  variant="outlined"
                  label="Departement"
                  placeholder='Ex: "13000"'
                  type="number"
                  fullWidth
                  inputProps={{ maxLength: 5 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              {errors?.birthPlaceDepartment && (
                <FormHelperText error>
                  {errors?.birthPlaceDepartment?.message}
                </FormHelperText>
              )}
            </Grid>
          )}
          <Grid item justifyContent="space-between" container>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isManualBirthPlaceVisible}
                  onChange={(e) =>
                    setIsManualBirthPlaceVisibleAndRemouve(e.target.checked)
                  }
                  color="primary"
                />
              }
              label={
                isManualBirthPlaceVisible
                  ? 'Retour à la recherche'
                  : 'Introuvable / Né(e) à l\'étranger ?'
              }
            />
            {isManualBirthPlaceVisible && (
              <Typography
                variant="caption"
                sx={{ fontSize: '0.75rem', color: 'grey', textAlign: 'center' }}
              >
                <FormattedMessage
                  id="birthPlaceTip"
                  defaultMessage="99 pour l'étranger"
                />
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* Médecin traitant */}
        <Grid item xs={6}>
          {/* Composant d'autocomplétion pour le médecin traitant */}
          <RppsAutocompleteByNameInput
            {...register('familyDoctor')}
            label={
              <FormattedMessage
                id="familyDoctor"
                defaultMessage="Médecin traitant"
              />
            }
            onChange={(value) => setValue('familyDoctor', value as any)}
            value={familyDoctor}
          />
          {errors?.familyDoctor && (
            <FormHelperText error>Champ requis</FormHelperText>
          )}
        </Grid>
        {/* Numéro de sécurité sociale */}
        <Grid item xs={3}>
          <TextField
            size="small"
            variant="outlined"
            // {...register('ssn')}
            label={
              <FormattedMessage
                id="ssn"
                defaultMessage="Numéro de sécurité sociale - 15 charactères dont la clé"
              />
            }
            fullWidth
            error={Boolean(errors?.ssn)}
            defaultValue={formatSsn(ssn)}
            onKeyPress={(event) => {
              const key = event.key
              if (!/\d/.test(key) && key !== 'Backspace' && key !== 'Delete') {
                event.preventDefault()
              }
            }}
            InputProps={{
              inputProps: {
                maxLength: 10,
              },
              disabled: !ssnStatus,
              startAdornment: ssnPrefix ? (
                <InputAdornment position="start">
                  {formatSsnPrefix(ssnPrefix)}
                </InputAdornment>
              ) : (
                <></>
              ),
            }}
            helperText={errors?.ssn?.message}
            InputLabelProps={{ shrink: true }}
          />
          {errors?.ssn && (
            <FormHelperText error>{errors?.ssn?.message}</FormHelperText>
          )}
        </Grid>
        {/* IOD */}
        <Grid item xs={3}>
          <TextField
            size="small"
            label="Oid"
            variant="outlined"
            type="oid"
            fullWidth
            {...register('iod')}
          />
        </Grid>
        {/* Ins */}
        <Grid item xs={6} sx={{ display: 'flex' }}>
          <Grid xs={8}>
            <TextField
              size="small"
              label="Ins"
              variant="outlined"
              type="ins"
              fullWidth
              {...register('ins')}
            />
          </Grid>
          <Grid
            xs={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Tooltip
              placement="top"
              title={ins ? 'Vérifier' : 'Envoyer une vérification'}
            >
              <Button
                variant="text"
                color={ins ? 'primary' : 'success'}
                sx={{ borderRadius: '100px' }}
              >
                {!ins ? (
                  <Icon className="fa fa-check" />
                ) : (
                  <Icon className="fa fa-times" />
                )}
              </Button>
            </Tooltip>
          </Grid>
          <Grid
            xs={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Tooltip placement="top" title="Historique des ins">
              <Button
                variant="text"
                sx={{ borderRadius: '100px' }}
                onClick={() => setIsModalHistoryOpen(true)}
              >
                <Icon className="fa fa-history" />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        {/* Commentaires */}
        <Grid item xs={6}>
          <TextField
            size="small"
            {...register('comments')}
            label="Comments"
            variant="outlined"
            inputProps={{ maxLength: 500 }}
            fullWidth
            multiline
            rows={4}
          />
          {errors?.comments && (
            <FormHelperText error>{errors?.comments?.message}</FormHelperText>
          )}
        </Grid>
        {mode === 'edit' ? (
          <Grid xs={5}>
            <Button
              style={{
                color: 'white',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                display: mode === 'edit' ? 'flex' : 'none',
              }}
              sx={{ borderRadius: 5, width: '80%', textAlign: 'left' }}
              variant="contained"
              color="info"
              onClick={() => callInsiVerification()}
            >
              Vérifier
              <img src={logoInsi} alt="logoInsi" height={40} />
            </Button>
          </Grid>
        ) : null}
        {mode === 'add' ? (
          <>
            <Grid
              xs={3}
              style={{
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Button
                style={{
                  color: 'white',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
                sx={{ borderRadius: 5, width: '95%', textAlign: 'left' }}
                variant="contained"
                color="info"
                onClick={() => changeDataACV()}
              >
                Recherche avec carte vital
                <img
                  src={logoInsi}
                  alt="logoInsi"
                  style={{ marginLeft: '5px' }}
                  height={40}
                />
              </Button>
            </Grid>
            <Grid
              xs={3}
              style={{
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Button
                style={{
                  color: 'white',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
                sx={{ borderRadius: 5, width: '95%', textAlign: 'left' }}
                variant="contained"
                color="info"
                onClick={() => callInsiSCV()}
              >
                Recherche sans carte vital
                <img
                  src={logoInsi}
                  alt="logoInsi"
                  style={{ marginLeft: '5px' }}
                  height={40}
                />
              </Button>
            </Grid>
          </>
        ) : null}
      </Grid>
      <DialogActions
        sx={{
          paddingBottom: '20px',
          paddingTop: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <Button
          style={{ color: 'white' }}
          sx={{ borderRadius: 5 }}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => onClose()}
        >
          Annuler
        </Button>
        <Button
          style={{ color: 'white' }}
          variant="contained"
          color="secondary"
          fullWidth
          type="submit"
          sx={{ borderRadius: 5 }}
        >
          Confirmer
        </Button>
      </DialogActions>
      <Dialog
        open={open}
        onClick={() => {
          setOpen(false)
          clearErrosMessage()
        }}
        maxWidth={'sm'}
        fullWidth
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            overflow: 'hidden',
            borderRadius: 5,
            background: '#E2E6F2',
          },
        }}
      >
        <DialogTitle
          title={'Erreur Insi'}
          style={{
            background: '#fff',
          }}
          onClick={() => {
            setOpen(false)
            clearErrosMessage()
          }}
        />
        <DialogContent
          style={{
            background: '#e91e63',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            minHeight: '120px',
          }}
        >
          <Icon
            className="fas fa-times-circle"
            fontSize="inherit"
            sx={{ color: '#FFF', fontSize: '100px' }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            bgcolor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <Typography sx={{ color: 'black' }} variant="h5" color="initial">
            {codeRetour ? codeRetour : ''}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
              marginBottom: '20px',
            }}
          >
            <Typography sx={{ color: 'black' }} variant="h5" color="initial">
              {erreurCode ? erreurCode + ': ' : ''}
            </Typography>
            <Typography sx={{ color: 'black' }} variant="h5" color="initial">
              {erreurMessage ? erreurMessage : ''}{' '}
            </Typography>
          </div>
          <Button
            style={{ color: 'white', width: '90%' }}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              setOpen(false)
              clearErrosMessage()
            }}
            sx={{ borderRadius: 5 }}
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Iframe */}
      <Dialog
        open={openIframe}
        onClose={() => setOpenIframe(false)}
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: 5,
            background: '#E2E6F2',
          },
        }}
      >
        <DialogTitle
          onClose={() => setOpenIframe(false)}
          title="Lecture de la carte vitale"
          style={{
            background: '#fff',
            color: '#000',
          }}
        />
        <DialogContent
          style={{
            minWidth: '850px',
            display: 'flex',
            justifyContent: 'center',
            background: '#E2E6F2',
          }}
        >
          <iframe
            style={{
              width: '100%',
              height: '80vh',
              border: 'none',
              overflow: 'hidden',
              marginTop: '20px',
            }}
            src={`http://localhost:5249/facturation/lectureCarte?idResip=1&dateLecteur=${date}&idLecteur=${lecteurId}}`}
          ></iframe>
        </DialogContent>
      </Dialog>
      <NirhistoryModal isOpen={isModalHistoryOpen} onClose={handleCloseModal} />
    </form>
  )
}
